import classnames from "classnames";
import React from "react";
import { renderWhatsappFormatWithVariables } from "ui/pages/whatsappBroadcast/whatsappBroadcast.constants";
import styles from "./WhatsAppTemplatePreview.module.css";
import WhatsAppMobilePlaceholder from "./modules/WhatsAppMobilePlaceholder/WhatsAppMobilePlaceholder";

const WhatsAppTemplatePreview = ({ body }) => {
  return (
    <div className="d-flex flex-column align-items-center">
      <div className={classnames("text-center user-select-none", styles.title)}>
        Preview
      </div>
      <div className={classnames("text-center user-select-none", styles.info)}>
        This is how your template will look
      </div>

      <WhatsAppMobilePlaceholder>
        {body && (
          <div
            className={styles.body}
            dangerouslySetInnerHTML={{
              __html: renderWhatsappFormatWithVariables({})(body),
            }}
          />
        )}
      </WhatsAppMobilePlaceholder>
    </div>
  );
};

export default WhatsAppTemplatePreview;
