import { RTEVariablesModal } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import { required } from "common/validate";
import { formatCommsVariableForRTE } from "features/Common/modules/Comms/Comms.utils";
import useInsertTextAtCursor from "features/Common/modules/Form/useInsertTextAtCursor";
import React, { useRef } from "react";
import { Field } from "react-final-form";
import { COMMS_FOCUSABLE_ELEMENTS } from "ui/pages/communications/CustomEmail/ComposeEmail/data";
import {
  EDIT_COMM_FE_KEYS,
  EDIT_COMM_FORM_KEYS,
} from "../../constants/EditAutomatedCommunication.constants";
import styles from "./CommSubjectField.module.css";

const CommSubjectField = ({
  data,
  anchorEl,
  onCloseVariables,
  setLastFocusedElement,
}) => {
  const {
    [EDIT_COMM_FORM_KEYS.email]: { [EDIT_COMM_FORM_KEYS.variables]: variables },
  } = data;
  const openVariableModal = !!anchorEl;

  const subjectInputRef = useRef();
  const { insertTextAtCursor: insertTextAtEmailSubjectCursor } =
    useInsertTextAtCursor({ inputRef: subjectInputRef });

  const handleEmailSubjectVariables = ({ value }) => {
    insertTextAtEmailSubjectCursor(value);
  };

  return (
    <div className={classnames("d-flex align-items-center", styles.root)}>
      <div className={styles.label}>Subject</div>

      <Field
        name={EDIT_COMM_FE_KEYS.fe_email_subject}
        component="input"
        type="text"
        className="border-none w-100"
        placeholder="Subject"
        validate={required}
        ref={subjectInputRef}
        onFocus={() => setLastFocusedElement(COMMS_FOCUSABLE_ELEMENTS.subject)}
      />

      <RTEVariablesModal
        open={openVariableModal}
        anchorEl={anchorEl}
        chips={formatCommsVariableForRTE(Object.values(variables))}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClickVariable={handleEmailSubjectVariables}
        onClose={onCloseVariables}
        ModalProps={{
          disablePortal: false,
        }}
        classes={{
          rootClassName: styles.variablesRoot,
        }}
      />
    </div>
  );
};

export default CommSubjectField;
