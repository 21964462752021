import React from "react";
import styles from "./SectionLabel.module.css";

const SectionLabel = ({ className, label, info }) => {
  return (
    <div className={className}>
      <div className={styles.label}>{label}</div>
      <div className={styles.info}>{info}</div>
    </div>
  );
};

export default SectionLabel;
