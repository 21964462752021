import { SearchRounded } from "@material-ui/icons";
import { Input } from "@my-scoot/component-library-legacy";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import classnames from "classnames";
import { AUTOMATED_COMMS_WA_PRESET_TEMPLATE_KEYS } from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { AUTOMATED_COMMS_URL_PARAMS } from "features/AutomatedCommunication/constants/AutomatedComms.urlParams";
import { EDIT_COMM_FE_KEYS } from "features/AutomatedCommunication/modules/EditAutomatedCommunication/constants/EditAutomatedCommunication.constants";
import { fetchWhatsappCustomPresetNotificationsTemplates } from "features/AutomatedCommunication/utils/AutomatedCommunication.apiCalls";
import { ExlyModal } from "features/Common/modules/ExlyModal/ExlyModal";
import { getRAUrlParams } from "features/Common/utils/url.utils";
import { TEMPLATE_STEPS } from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";
import { WHATSAPP_TEMPLATES_FILTER_KEYS } from "features/CreateWhatsappPresetNotificationTemplate/modules/TemplateSelection/modules/TemplatesList/modules/TemplateFilters/constants/TemplateFilters.constants";
import { redirectToCreatePresetNotificationTemplates } from "features/CreateWhatsappPresetNotificationTemplate/utils/CreateWhatsappPresetNotificationTemplate.utils";
import { GET_CUSTOM_WHATSAPP_TEMPLATES } from "features/OmniChannel/modules/Whatsapp/modules/WhatsappTemplates/constants/WhatsappTemplates.api";
import debounce from "lodash/debounce";
import React, { useCallback, useState } from "react";
import { useForm } from "react-final-form";
import { useHistory } from "react-router-dom";
import WhatsAppCommPreviewPopup from "../WhatsAppCommPreviewPopup/WhatsAppCommPreviewPopup";
import styles from "./ChangeWhatsAppTemplate.module.css";
import WhatsAppTemplatePreview from "./modules/WhatsAppTemplatePreview/WhatsAppTemplatePreview";
import { WhatsappTemplatesList } from "./modules/WhatsappTemplatesList/WhatsappTemplatesList";
import { formatTemplate } from "./utils/ChangeWhatsAppTemplate.utils";

const ChangeWhatsAppTemplate = ({ open, onClose }) => {
  const history = useHistory();
  const isDesktop = useIsDesktop();
  const form = useForm();
  const urlParams = getRAUrlParams();
  const commTitle = urlParams.get(AUTOMATED_COMMS_URL_PARAMS.comm_title);
  const commInfo = urlParams.get(AUTOMATED_COMMS_URL_PARAMS.comm_info);
  const [previewState, setPreviewState] = useState();
  const [filters, setFilters] = useState({});
  const isWhatsAppPreviewOpen = !!previewState;

  const handleSetSearchQuery = (input) => {
    const templateName = input?.trim();

    setFilters(
      templateName
        ? {
            [WHATSAPP_TEMPLATES_FILTER_KEYS.search]: templateName,
          }
        : {}
    );
  };

  const handleSearchTemplate = useCallback(
    debounce(handleSetSearchQuery, 1000),
    []
  );

  const handleTemplateQueryChange = (e) => {
    const {
      target: { value },
    } = e;
    handleSearchTemplate(value);
  };

  const handleOpenPreview = (template) => {
    setPreviewState(template);
  };

  const handleClosePreview = () => {
    setPreviewState();
  };

  const handleSelectTemplate = (template) => {
    const { [AUTOMATED_COMMS_WA_PRESET_TEMPLATE_KEYS.uuid]: templateUuid } =
      template;
    form.change(EDIT_COMM_FE_KEYS.fe_whatsapp_template_uuid, templateUuid);
    onClose();
  };

  const handleCreateTemplate = () => {
    redirectToCreatePresetNotificationTemplates({
      history,
      step: TEMPLATE_STEPS.TEMPLATE_SELECTION,
      redirectUrlOnSuccess: window.location.href,
      parentCommTitle: commTitle,
    });
  };

  return (
    <ExlyModal
      title="Select Template"
      open={open}
      onClose={onClose}
      customFooter={<></>}
      className={styles.root}
      modal_props={{
        modalPaperClassName: styles.paper,
        dialogContentClassName: styles.dialogRoot,
      }}
    >
      <div className={styles.interface}>
        <Input
          onChange={handleTemplateQueryChange}
          placeholder="Search for templates"
          iconPosition="end"
          icon={<SearchRounded />}
          className={styles.input}
          fullWidth={!isDesktop}
        />

        <hr className={classnames("my-4", styles.hr)} />

        <div>
          <WhatsappTemplatesList
            api={GET_CUSTOM_WHATSAPP_TEMPLATES}
            queryFn={fetchWhatsappCustomPresetNotificationsTemplates(commTitle)}
            formatTemplateData={formatTemplate(commInfo)}
            open={open}
            filters={filters}
            templateQuery={filters[WHATSAPP_TEMPLATES_FILTER_KEYS.search]}
            emptyStateProps={{
              primaryCtaProps: {
                title: "Create Template",
                onClick: handleCreateTemplate,
              },
            }}
            onChoose={handleSelectTemplate}
            onPreview={handleOpenPreview}
          />
        </div>
      </div>

      {isDesktop ? (
        <div className={styles.previewContainer}>
          <WhatsAppTemplatePreview
            body={
              previewState?.[
                AUTOMATED_COMMS_WA_PRESET_TEMPLATE_KEYS.whatsapp_body
              ]
            }
          />
        </div>
      ) : (
        <WhatsAppCommPreviewPopup
          open={isWhatsAppPreviewOpen}
          onClose={handleClosePreview}
        />
      )}
    </ExlyModal>
  );
};

export default ChangeWhatsAppTemplate;
