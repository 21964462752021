import { AUTOMATED_COMMS_TARGETS } from "features/AutomatedCommunication/constants/AutomatedComms.targets";
import { useMasterSettings } from "../modules/MasterControls/modules/CategoryWiseSettings/utils/useMasterSettings";
import { useSenderManagementSettings } from "../modules/MasterControls/modules/CategoryWiseSettings/utils/useSenderManagementSettings";

export const useCommSettingsModal = ({ enabled }) => {
  const { isSuccess: isUserCommSettingsLoaded, data: userCommsSettings } =
    useMasterSettings({
      commTarget: AUTOMATED_COMMS_TARGETS.SENT_TO_USERS,
      enabled,
    });
  const { isSuccess: isCreatorCommSettingsLoaded, data: creatorCommsSettings } =
    useMasterSettings({
      commTarget: AUTOMATED_COMMS_TARGETS.SENT_TO_CREATOR,
      enabled,
    });
  const { isSuccess: isSenderSettingsLoaded, data: senderSettings } =
    useSenderManagementSettings({ enabled });

  const isLoaded =
    isUserCommSettingsLoaded &&
    isCreatorCommSettingsLoaded &&
    isSenderSettingsLoaded;

  return {
    isLoaded,
    userCommsSettings,
    creatorCommsSettings,
    senderSettings,
  };
};
