import CircularProgress from "@material-ui/core/CircularProgress";
import { useCustomQuery } from "data/modules/api/hooks/useCustomQuery";
import { EDIT_COMM_FE_KEYS } from "features/AutomatedCommunication/modules/EditAutomatedCommunication/constants/EditAutomatedCommunication.constants";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";
import React from "react";
import { whatsapp_broadcasts_apis } from "ui/pages/whatsappBroadcast/whatsappBroadCast.api";
import { renderWhatsappFormatWithVariables } from "ui/pages/whatsappBroadcast/whatsappBroadcast.constants";
import styles from "./WhatsappCommBody.module.css";
import { Field } from "react-final-form";

export const WhatsappCommBody = () => {
  const templateUuid = useFormFieldValue(
    EDIT_COMM_FE_KEYS.fe_whatsapp_template_uuid
  );
  const { isLoading, data: { template: { body } = {} } = {} } = useCustomQuery({
    url: whatsapp_broadcasts_apis.get_templates,
    queryKey: [
      whatsapp_broadcasts_apis.get_templates,
      { template_uuid: templateUuid },
    ],
    params: { template_uuid: templateUuid },
  });

  if (isLoading)
    return (
      <div className="text-center m-4">
        <CircularProgress />
      </div>
    );

  return (
    <>
      <Field
        name={EDIT_COMM_FE_KEYS.fe_whatsapp_template_uuid}
        component={() => null}
      />

      <div
        className={styles.body}
        dangerouslySetInnerHTML={{
          __html: renderWhatsappFormatWithVariables({})(body),
        }}
      />
    </>
  );
};
