import { useCustomQuery } from "data/modules/api/hooks/useCustomQuery";
import { AUTOMATED_COMMS_APIS } from "features/AutomatedCommunication/constants/AutomatedComms.api";

export const useMasterSettings = ({ commTarget, enabled }) => {
  return useCustomQuery({
    url: AUTOMATED_COMMS_APIS.manage_master_settings,
    queryKey: [
      AUTOMATED_COMMS_APIS.manage_master_settings,
      { recipient: commTarget },
    ],
    params: { recipient: commTarget },
    cacheTime: 0,
    enabled,
  });
};
