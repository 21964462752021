import { AUTOMATED_COMMS_TARGETS } from "./AutomatedComms.targets";
import {
  AUTOMATED_COMMS_CUSTOM_ROUTE_KEYS,
  AUTOMATED_COMMS_ROUTE_IDS,
} from "./AutomatedComms.urlPaths";

// display name for the automated communications feature (used on the main page and other references)
export const AUTOMATED_COMMS_FEATURE_NAME = "Manage Notifications";

// tab options visible on automated communications main page
export const AUTOMATED_COMMS_TABS = [
  { label: "Sent to users", value: AUTOMATED_COMMS_TARGETS.SENT_TO_USERS },
  {
    label: "Sent to me / org members",
    value: AUTOMATED_COMMS_TARGETS.SENT_TO_CREATOR,
  },
];

// communication mediums included in the automated communications API payload
export const AUTOMATED_SUPPORTED_COMMS_API_VALUES = {
  EMAIL: "EMAIL",
  WHATSAPP: "WHATSAPP",
  SMS: "SMS",
};

// comm target labels
export const SUPPORTED_COMMS_LABELS = {
  [AUTOMATED_SUPPORTED_COMMS_API_VALUES.EMAIL]: "Email",
  [AUTOMATED_SUPPORTED_COMMS_API_VALUES.WHATSAPP]: "WhatsApp",
  [AUTOMATED_SUPPORTED_COMMS_API_VALUES.SMS]: "SMS",
};

// whatsapp template target offering categories
export const WA_TEMPLATE_TARGET_OFFERING_TYPES = {
  GENERAL: "general",
  WEBINAR: "webinar",
  WORKSHOP: "workshop",
  RECORDED_CONTENT: "recorded_content",
  BRANDED: "branded",
  WHATSAPP: "whatsapp",
  TELEGRAM: "telegram",
  APPOINTMENT: "appointment",
  BATCH: "batch",
  ROLLING_CLASS: "rolling_class",
  MERCHANDISE: "merchandise",
  ONE_ONE_MONTHLY: "one_one_monthly",
  NO_SCHEDULE: "no_schedule",
  QUICK_PAYMENT_PAGES: "no_schedule",
  REPORTS: "reports",
  PURCHASE_CONFIRMATION: "purchase_confirmation",
  UPDATES: "updates",
  REMINDERS: "reminders",
};

// whatsapp template offering type category labels
export const WA_TEMPLATE_TARGET_OFFERING_TYPE_LABELS = {
  [WA_TEMPLATE_TARGET_OFFERING_TYPES.WORKSHOP]: "Workshops",
  [WA_TEMPLATE_TARGET_OFFERING_TYPES.RECORDED_CONTENT]:
    "Courses: Recorded Video / Files",
  [WA_TEMPLATE_TARGET_OFFERING_TYPES.WEBINAR]: "Live Webinar",
  [WA_TEMPLATE_TARGET_OFFERING_TYPES.APPOINTMENT]: "Appointments",
  [WA_TEMPLATE_TARGET_OFFERING_TYPES.BATCH]: "Group Classes",
  [WA_TEMPLATE_TARGET_OFFERING_TYPES.ROLLING_CLASS]: "Flexible Classes",
  [WA_TEMPLATE_TARGET_OFFERING_TYPES.BRANDED]: "Branded Community",
  [WA_TEMPLATE_TARGET_OFFERING_TYPES.WHATSAPP]: "WhatsApp Community",
  [WA_TEMPLATE_TARGET_OFFERING_TYPES.TELEGRAM]: "Telegram Community",
  [WA_TEMPLATE_TARGET_OFFERING_TYPES.MERCHANDISE]: "Merchandise",
  [WA_TEMPLATE_TARGET_OFFERING_TYPES.QUICK_PAYMENT_PAGES]:
    "Quick Payment Pages",
  [WA_TEMPLATE_TARGET_OFFERING_TYPES.GENERAL]: "General",
};

// whatsapp template category filter options
export const WA_TEMPLATE_TARGET_OFFERING_TYPE_OPTIONS = Object.keys(
  WA_TEMPLATE_TARGET_OFFERING_TYPE_LABELS
).map((key) => ({
  id: key,
  label: WA_TEMPLATE_TARGET_OFFERING_TYPE_LABELS[key],
  value: key,
}));

// automated comm table api data keys
export const AUTOMATED_COMMS_TABLE_KEYS = {
  name: "name", // table name
  key: "key", // table unique key
  comms: "comms", // table comm rows
  is_any_listing_comms_disabled: "is_any_listing_comms_disabled", // map of offering counts per communication medium where the medium is disabled
  total_listing_count: "total_listing_count", // total listing count under this comm
};

// these keys only needed at front-end
export const AUTOMATED_COMMS_TABLE_FE_KEYS = {
  fe_is_any_listing_comms_disabled: "fe_is_any_listing_comms_disabled",
  fe_enabled_mediums: "fe_enabled_mediums",
  fe_total_listing_count: "fe_total_listing_count",
  fe_offerings_comms: "fe_offerings_comms",
};

// automated comms table row api data keys
export const AUTOMATED_COMMS_TABLE_ROW_KEYS = {
  title: "title", // comm unique title (using it to fetch comm details)
  cdb_type: "cdb_type", // comm label
  trigger: "trigger", // comm info
  recipient: "recipient",
  cdb_visible: "cdb_visible", // flag to control the visibility of communications in the Creator Dashboard (CDB)
  is_creator_testable: "is_creator_testable",
  group_choice: "group_choice",
  category_type: "category_type",
  enabled: "enabled",
  enabled_communication_mediums: "enabled_communication_mediums", // comm mediums enabled for this comm
  has_default_message_content: "has_default_message_content", // indicates this comm has default message content (not edited)
  allowed_communication_mediums: "allowed_communication_mediums", // allowed comm mediums for this comm type
  total_listing_count: "total_listing_count", // total listing available under this comm type
};

// automated comms offering rows data keys
export const AUTOMATED_COMM_LISTING_KEYS = {
  title: "title", // offering name
  enabled_communication_mediums: "enabled_communication_mediums", // comm mediums enabled for this offering
  listing_uuid: "listing_uuid",
  allowed_communication_mediums: "allowed_communication_mediums", // allowed mediums for this offering
  has_default_message_content: "has_default_message_content", // indicate this offering has default message content (not edited)
};

// api payload keys to update comm
export const AUTOMATED_COMM_UPDATE_PAYLOAD_KEYS = {
  enabled_communication_mediums: "enabled_communication_mediums", // updated list of enabled communication mediums
  reset_content: "reset_content", // array of mediums for which message content should be reset
  listings: "listings", // array of listings for which message content should be reset
};

export const AUTOMATED_COMM_LISTING_UPDATE_PAYLOAD_KEYS = {
  uuid: "uuid", // comm offering uuid
  enabled_communication_mediums: "enabled_communication_mediums", // updated list of enabled communication mediums for this offering
  reset_content: "reset_content", // array of mediums for which message content should be reset for this offering
};

export const AUTOMATED_COMMS_STORAGE_KEYS = {
  // Stores the last scroll position of the automated communications main page before navigating to the edit communication page
  scroll_position: `scroll-${
    AUTOMATED_COMMS_ROUTE_IDS[AUTOMATED_COMMS_CUSTOM_ROUTE_KEYS.AUTOMATED_COMMS]
  }`,
};

// order of comm medium input fields
export const COMMS_TYPES_COLUMN_ORDER = [
  AUTOMATED_SUPPORTED_COMMS_API_VALUES.EMAIL,
  AUTOMATED_SUPPORTED_COMMS_API_VALUES.WHATSAPP,
  AUTOMATED_SUPPORTED_COMMS_API_VALUES.SMS,
];

// comm mediums options
export const SUPPORTED_COMMS_OPTIONS = [
  {
    label: SUPPORTED_COMMS_LABELS[AUTOMATED_SUPPORTED_COMMS_API_VALUES.EMAIL],
    value: AUTOMATED_SUPPORTED_COMMS_API_VALUES.EMAIL,
  },
  {
    label:
      SUPPORTED_COMMS_LABELS[AUTOMATED_SUPPORTED_COMMS_API_VALUES.WHATSAPP],
    value: AUTOMATED_SUPPORTED_COMMS_API_VALUES.WHATSAPP,
  },
  {
    label: SUPPORTED_COMMS_LABELS[AUTOMATED_SUPPORTED_COMMS_API_VALUES.SMS],
    value: AUTOMATED_SUPPORTED_COMMS_API_VALUES.SMS,
  },
];

// whatsapp present notification template data keys
export const AUTOMATED_COMMS_WA_PRESET_TEMPLATE_KEYS = {
  title: "title", // comm unique title (used for fetching comm details)
  cdb_type: "cdb_type", // comm display title
  whatsapp_body: "whatsapp_body", // template body
  category_type: "category_type", // comm target category type (enum: WA_TEMPLATE_TARGET_OFFERING_TYPES)
  category_name: "category_name", // comm target category name (enum: WA_TEMPLATE_TARGET_OFFERING_TYPE_LABELS)
  uuid: "uuid", // template uuid
};
