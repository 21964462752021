import {
  AUTOMATED_COMMS_WA_PRESET_TEMPLATE_KEYS,
  WA_TEMPLATE_TARGET_OFFERING_TYPE_LABELS,
} from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { TEMPLATE_FORM_KEYS } from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";

export const formatTemplate = (commInfo) => (template) => {
  const { category_type, [TEMPLATE_FORM_KEYS.body]: body } = template;

  return {
    ...template,
    [AUTOMATED_COMMS_WA_PRESET_TEMPLATE_KEYS.whatsapp_body]: body,
    [AUTOMATED_COMMS_WA_PRESET_TEMPLATE_KEYS.category_name]:
      WA_TEMPLATE_TARGET_OFFERING_TYPE_LABELS[category_type],
    [AUTOMATED_COMMS_WA_PRESET_TEMPLATE_KEYS.cdb_type]: commInfo,
  };
};
