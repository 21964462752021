import { Button } from "@material-ui/core";
import { InfoOutlined } from "@material-ui/icons";
import { Tooltip } from "@my-scoot/component-library-legacy";
import {
  AUTOMATED_COMM_LISTING_KEYS,
  AUTOMATED_COMMS_TABLE_ROW_KEYS,
} from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { AUTOMATED_COMMS_URL_PARAMS } from "features/AutomatedCommunication/constants/AutomatedComms.urlParams";
import { useCommStateValue } from "features/AutomatedCommunication/store/useAutomatedCommsStore.utils";
import { ExlyModal } from "features/Common/modules/ExlyModal/ExlyModal";
import React from "react";
import { useToggleState } from "utils/hooks";
import EditCommOfferingsPopup from "../EditCommOfferingsPopup/EditCommOfferingsPopup";
import styles from "./ActionsPopup.module.css";

const ActionsPopup = ({
  open,
  comm,
  categoryKey,
  onClose,
  onReset,
  onEdit,
  canEdit,
}) => {
  const {
    [AUTOMATED_COMMS_TABLE_ROW_KEYS.title]: commTitle,
    [AUTOMATED_COMMS_TABLE_ROW_KEYS.cdb_type]: cdbType,
    [AUTOMATED_COMMS_TABLE_ROW_KEYS.total_listing_count]: totalListingCount,
  } = comm;
  const [isEditOfferingOpen, openEditOfferings, closeEditOfferings] =
    useToggleState(false);
  const hasDefaultSettings = useCommStateValue({
    commTitle,
    key: AUTOMATED_COMMS_TABLE_ROW_KEYS.has_default_message_content,
  });

  const handleResetParentComm = () => {
    onReset({ commTitle });
  };

  const handleEditComm = (params = {}) => {
    const { [AUTOMATED_COMM_LISTING_KEYS.listing_uuid]: listingUuid } = params;

    onEdit({
      [AUTOMATED_COMMS_URL_PARAMS.category]: categoryKey,
      [AUTOMATED_COMMS_URL_PARAMS.comm_title]: commTitle,
      [AUTOMATED_COMMS_URL_PARAMS.comm_info]: cdbType,
      [AUTOMATED_COMMS_URL_PARAMS.listing_uuid]: listingUuid,
    });
  };

  const actions = [
    {
      label: "Edit message",
      onClick: handleEditComm,
      hide: !canEdit,
    },
    {
      label: "Control the offerings for this communication",
      onClick: openEditOfferings,
      hide: !totalListingCount,
    },
    {
      label: "Reset message",
      tooltip: "Reset communication",
      onClick: handleResetParentComm,
      hide: hasDefaultSettings,
    },
  ];

  return (
    <ExlyModal
      title={cdbType}
      open={open}
      onClose={onClose}
      customFooter={<></>}
      className={styles.root}
      mobile_modal_props={{ keepMounted: true }}
      modal_props={{
        keepMounted: true,
        footerBtnsPosition: "space-between",
      }}
    >
      {actions.map(
        ({ label, tooltip, hide, onClick }) =>
          !hide && (
            <div key={label} className="d-flex align-items-center">
              <Button className="flex-grow-1" onClick={onClick}>
                {label}
              </Button>

              {tooltip && (
                <Tooltip title={tooltip} color="primary" arrow>
                  <InfoOutlined className={styles.info} />
                </Tooltip>
              )}
            </div>
          )
      )}

      <EditCommOfferingsPopup
        open={isEditOfferingOpen}
        categoryKey={categoryKey}
        comm={comm}
        onClose={closeEditOfferings}
        onReset={onReset}
        onEdit={handleEditComm}
      />
    </ExlyModal>
  );
};

export default ActionsPopup;
