import {
  AUTOMATED_COMMS_STORAGE_KEYS,
  AUTOMATED_SUPPORTED_COMMS_API_VALUES,
} from "../constants/AutomatedComms.constants";
import { AUTOMATED_COMMS_TARGETS } from "../constants/AutomatedComms.targets";

export const isWhatsAppCommType = (type) =>
  type === AUTOMATED_SUPPORTED_COMMS_API_VALUES.WHATSAPP;

export const isEmailCommType = (type) =>
  type === AUTOMATED_SUPPORTED_COMMS_API_VALUES.EMAIL;

export const isSMSCommType = (type) =>
  type === AUTOMATED_SUPPORTED_COMMS_API_VALUES.SMS;

export const isTargetCommUsers = (target) =>
  target === AUTOMATED_COMMS_TARGETS.SENT_TO_USERS;

export const isTargetCommCreator = (target) =>
  target === AUTOMATED_COMMS_TARGETS.SENT_TO_CREATOR;

export const isTargetCommAffiliate = (target) =>
  target === AUTOMATED_COMMS_TARGETS.SENT_TO_AFFILIATES;

export const handleScroll = () => {
  const savedPosition = sessionStorage.getItem(
    AUTOMATED_COMMS_STORAGE_KEYS.scroll_position
  );
  if (savedPosition) {
    window.scrollTo(0, parseInt(savedPosition, 10));
    sessionStorage.setItem(AUTOMATED_COMMS_STORAGE_KEYS.scroll_position, 0);
  }
};

export const handleSetLastScrollPosition = () => {
  sessionStorage.setItem(
    AUTOMATED_COMMS_STORAGE_KEYS.scroll_position,
    window.scrollY.toString()
  );
};

export const canEditComm = (allowedComms) => {
  if (!allowedComms) return false;

  return Object.values(AUTOMATED_SUPPORTED_COMMS_API_VALUES).some((medium) =>
    allowedComms.includes(medium)
  );
};
