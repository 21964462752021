import { useCustomQuery } from "data/modules/api/hooks/useCustomQuery";
import { AUTOMATED_COMMS_APIS } from "features/AutomatedCommunication/constants/AutomatedComms.api";

export const useSenderManagementSettings = ({ enabled }) => {
  return useCustomQuery({
    url: AUTOMATED_COMMS_APIS.manage_sender_management,
    queryKey: [AUTOMATED_COMMS_APIS.manage_sender_management],
    cacheTime: 0,
    enabled,
  });
};
