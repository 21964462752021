import { Checkbox } from "@my-scoot/component-library-legacy";
import { SUPPORTED_COMMS_OPTIONS } from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { MASTER_CONTROLS_FORM_KEYS } from "features/AutomatedCommunication/modules/CommsSettingsModal/modules/MasterControls/constants/MasterControls.constants";
import { hasCommType } from "features/AutomatedCommunication/modules/CommsTable/modules/CommRow/utils/CommRow.utils";
import React from "react";
import { Field } from "react-final-form";
import { isCategoryCommsEqual } from "../../../../utils/CategoryWiseSettings.utils";

export const SettingsCommRow = ({ name: fieldName, comm, onChange }) => {
  const {
    [MASTER_CONTROLS_FORM_KEYS.name]: name,
    [MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums]: enabledComms,
  } = comm;

  const generateCommChangeHandler = (commType) => (e, activate) => {
    onChange(commType, activate);
  };

  return (
    <tr>
      <Field
        name={fieldName}
        component={() => null}
        isEqual={isCategoryCommsEqual}
      />

      <td>{name}</td>

      {SUPPORTED_COMMS_OPTIONS.map(({ value }) => (
        <td key={value} align="center">
          <Checkbox
            wrapperClassName="bg-white"
            checked={hasCommType(enabledComms, value)}
            color="#00B77A"
            onChange={generateCommChangeHandler(value)}
          />
        </td>
      ))}
    </tr>
  );
};
