import React from "react";
import InfoAction from "../InfoAction/InfoAction";
import styles from "./SMSInfo.module.css";

const SMSInfo = () => {
  return (
    <InfoAction
      info="Editing SMS is not currently supported"
      classes={{ root: styles.root }}
    />
  );
};

export default SMSInfo;
