import EmailIcon from "@material-ui/icons/MailOutlineRounded";
import SMSIcon from "@material-ui/icons/SmsOutlined";
import classnames from "classnames";
import ExlyImage from "common/Components/ExlyImage";
import {
  AUTOMATED_SUPPORTED_COMMS_API_VALUES,
  SUPPORTED_COMMS_LABELS,
} from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { useAutomatedCommsTabs } from "features/AutomatedCommunication/utils/useAutomatedCommsTabs";
import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";
import React from "react";
import { useForm } from "react-final-form";
import {
  EDIT_COMM_FORM_KEYS,
  EDIT_COMM_URL_PARAMS,
} from "../../constants/EditAutomatedCommunication.constants";
import styles from "./CommTypeTabs.module.css";

export const CommTypeTabs = () => {
  const form = useForm();
  const commType = useFormFieldValue(EDIT_COMM_FORM_KEYS.comm_type);
  const allowedCommMediums = useFormFieldValue(
    EDIT_COMM_FORM_KEYS.allowed_communication_mediums
  );
  const { handleTabChange } = useAutomatedCommsTabs({
    defaultValue: AUTOMATED_SUPPORTED_COMMS_API_VALUES.EMAIL,
    key: EDIT_COMM_URL_PARAMS.ct,
  });

  const handleChange = (newValue) => () => {
    form.change(EDIT_COMM_FORM_KEYS.comm_type, newValue);
    handleTabChange(undefined, newValue);
  };

  const COMM_TYPE_TABS = [
    {
      label: SUPPORTED_COMMS_LABELS[AUTOMATED_SUPPORTED_COMMS_API_VALUES.EMAIL],
      value: AUTOMATED_SUPPORTED_COMMS_API_VALUES.EMAIL,
      icon: EmailIcon,
      hide: !allowedCommMediums.includes(
        AUTOMATED_SUPPORTED_COMMS_API_VALUES.EMAIL
      ),
    },
    {
      label:
        SUPPORTED_COMMS_LABELS[AUTOMATED_SUPPORTED_COMMS_API_VALUES.WHATSAPP],
      value: AUTOMATED_SUPPORTED_COMMS_API_VALUES.WHATSAPP,
      icon: () => (
        <ExlyImage
          src={getS3AssetCDNUrl(
            "assets/vectors/social_icons/whatsapp_gray.svg"
          )}
          alt="whatsapp"
        />
      ),
      hide: !allowedCommMediums.includes(
        AUTOMATED_SUPPORTED_COMMS_API_VALUES.WHATSAPP
      ),
    },
    {
      label: SUPPORTED_COMMS_LABELS[AUTOMATED_SUPPORTED_COMMS_API_VALUES.SMS],
      value: AUTOMATED_SUPPORTED_COMMS_API_VALUES.SMS,
      icon: SMSIcon,
      hide: !allowedCommMediums.includes(
        AUTOMATED_SUPPORTED_COMMS_API_VALUES.SMS
      ),
    },
  ];

  return (
    <div className={styles.root}>
      {COMM_TYPE_TABS.map(
        ({ label, value, icon: TabIcon, hide }) =>
          !hide && (
            <div
              key={value}
              className={classnames(
                "d-flex align-items-center",
                styles.tabLabel,
                commType === value && styles.acitve
              )}
              onClick={handleChange(value)}
            >
              <TabIcon className={styles.tabIcon} />
              {commType === value ? label : ""}
            </div>
          )
      )}
    </div>
  );
};
