import { CircularProgress } from "@material-ui/core";
import { RichTextEditorUtils } from "@my-scoot/component-library-legacy";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { useCustomMutation } from "data/modules/api/hooks/useCustomMutation";
import { useCustomQuery } from "data/modules/api/hooks/useCustomQuery";
import { AUTOMATED_COMMS_APIS } from "features/AutomatedCommunication/constants/AutomatedComms.api";
import { AUTOMATED_COMMS_URL_PARAMS } from "features/AutomatedCommunication/constants/AutomatedComms.urlParams";
import {
  isEmailCommType,
  isSMSCommType,
  isWhatsAppCommType,
} from "features/AutomatedCommunication/utils/AutomatedCommunication.utils";
import { getRAUrlParams } from "features/Common/utils/url.utils";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { COMMS_FOCUSABLE_ELEMENTS } from "ui/pages/communications/CustomEmail/ComposeEmail/data";
import { notification_color_keys, useNotifications } from "utils/hooks";
import {
  EDIT_COMM_FE_KEYS,
  EDIT_COMM_FORM_KEYS,
} from "./constants/EditAutomatedCommunication.constants";
import styles from "./EditAutomatedCommunication.module.css";
import CommBody from "./modules/CommBody/CommBody";
import { CommEditStatusModal } from "./modules/CommEditStatusModal/CommEditStatusModal";
import { CommFooter } from "./modules/CommFooter/CommFooter";
import CommSubjectField from "./modules/CommSubjectField/CommSubjectField";
import CommToolbar from "./modules/CommToolbar/CommToolbar";
import ConnectWhatsAppNumberStrip from "./modules/ConnectWhatsAppNumberStrip/ConnectWhatsAppNumberStrip";
import EditCommPageHeader from "./modules/EditCommPageHeader/EditCommPageHeader";
import EditWhatsAppMessageAction from "./modules/EditWhatsAppMessageAction/EditWhatsAppMessageAction";
import { MobileHeader } from "./modules/MobileHeader/MobileHeader";
import SMSInfo from "./modules/SMSInfo/SMSInfo";
import { parseFormState } from "./utils/EditAutomatedCommunication.utils";

const EditAutomatedCommunication = () => {
  const { notify } = useNotifications();
  const isDesktop = useIsDesktop();
  const urlParams = getRAUrlParams();
  const commTitle = urlParams.get(AUTOMATED_COMMS_URL_PARAMS.comm_title);
  const listingUuid = urlParams.get(AUTOMATED_COMMS_URL_PARAMS.listing_uuid);

  const [commVariablesModalAnchorEl, setCommVariablesModalAnchorEl] =
    useState(null);
  const [emailSubjectAnchorEl, setEmailSubjectAnchorEl] = useState(null);
  const [lastFocusedElement, setLastFocusedElement] = useState(null);

  const { data, isFetching, isRefetching, refetch } = useCustomQuery({
    url: AUTOMATED_COMMS_APIS.fetch_comm_details({ commTitle, listingUuid }),
    queryKey: [
      AUTOMATED_COMMS_APIS.fetch_comm_details({ commTitle, listingUuid }),
    ],
    cacheTime: 0, // not allowing caching here as creator may come to edit comm after chaning master settings
  });

  const handleSuccess = () => {
    notify("Message saved successfully", notification_color_keys.success);
    refetch().then(({ data }) => parseFormState(data));
  };

  const handleError = (error) => {
    notify(
      error?.response?.data?.message || SOMETHING_WENT_WRONG,
      notification_color_keys.error
    );
  };

  const { mutate: saveAutomatedComms } = useCustomMutation({
    url: AUTOMATED_COMMS_APIS.save_edited_comm(commTitle),
    onSuccess: handleSuccess,
    onError: handleError,
  });

  if (isFetching && !isRefetching)
    return (
      <div className="text-center m-4">
        <CircularProgress />
      </div>
    );

  const {
    [EDIT_COMM_FORM_KEYS.whatsapp]: {
      [EDIT_COMM_FORM_KEYS.use_whitelabel_wa_number]: isWhatsAppIntegrated,
    },
  } = data ?? {};
  const WhatsAppAction = isWhatsAppIntegrated
    ? EditWhatsAppMessageAction
    : ConnectWhatsAppNumberStrip;

  const handleSubmit = (values, form) => {
    // const commCategory = urlParams.get(AUTOMATED_COMMS_URL_PARAMS.category);
    const listingUuid = urlParams.get(AUTOMATED_COMMS_URL_PARAMS.listing_uuid);
    const payload = {};

    if (listingUuid) {
      payload.listing_uuid = listingUuid;
    }

    // collect email data changes in payload
    const emailBodyState =
      form.getFieldState(EDIT_COMM_FE_KEYS.fe_email_body) ?? {};
    const emailSubjectState =
      form.getFieldState(EDIT_COMM_FE_KEYS.fe_email_subject) ?? {};
    const { dirty: isEmailSubjectChanged } = emailSubjectState;
    const { dirty: isEmailBodyChanged } = emailBodyState;
    if (isEmailSubjectChanged || isEmailBodyChanged) {
      const emailPayload = {
        subject: emailSubjectState.value,
        body: RichTextEditorUtils.getHtmlFromEditorState(emailBodyState.value),
      };
      payload.email = emailPayload;
    }

    // collect whatsapp data changes in payload
    const whatsappTemplateUuidState =
      form.getFieldState(EDIT_COMM_FE_KEYS.fe_whatsapp_template_uuid) ?? {};
    const { dirty: isWhatsappTemplateUuidChanged } = whatsappTemplateUuidState;
    if (isWhatsappTemplateUuidChanged) {
      payload.whatsapp = {
        [EDIT_COMM_FORM_KEYS.template_uuid]: whatsappTemplateUuidState.value,
      };
    }

    return new Promise((onSuccess, onError) =>
      saveAutomatedComms(payload, { onSuccess, onError })
    );
  };

  const handlePersonalize = (e) => {
    e.stopPropagation();

    if (lastFocusedElement === COMMS_FOCUSABLE_ELEMENTS.subject) {
      setEmailSubjectAnchorEl(e.currentTarget);
    } else if (lastFocusedElement === COMMS_FOCUSABLE_ELEMENTS.rte) {
      setCommVariablesModalAnchorEl(e.currentTarget);
    } else {
      notify(
        "Select the subject or body to insert variables",
        notification_color_keys.info
      );
    }
  };

  const handleClosePersonalize = () => {
    setCommVariablesModalAnchorEl(null);
    setEmailSubjectAnchorEl(null);
  };

  return (
    <Form
      initialValues={parseFormState(data)}
      onSubmit={handleSubmit}
      keepDirtyOnReinitialize
    >
      {({
        handleSubmit,
        submitting,
        values: { [EDIT_COMM_FORM_KEYS.comm_type]: commType },
      }) => (
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.content}>
            {isDesktop ? (
              <EditCommPageHeader
                submitting={submitting}
                onSave={handleSubmit}
              />
            ) : (
              <MobileHeader
                submitting={submitting}
                commType={commType}
                onSave={handleSubmit}
              />
            )}

            <CommToolbar />

            {isEmailCommType(commType) && (
              <CommSubjectField
                data={data}
                anchorEl={emailSubjectAnchorEl}
                onCloseVariables={handleClosePersonalize}
                setLastFocusedElement={setLastFocusedElement}
              />
            )}

            {isWhatsAppCommType(commType) && (
              <WhatsAppAction commTitle={commTitle} />
            )}

            {isSMSCommType(commType) && <SMSInfo />}

            <CommBody
              data={data}
              commVariablesModalAnchorEl={commVariablesModalAnchorEl}
              handleCloseCommVariablesModal={handleClosePersonalize}
              setLastFocusedElement={setLastFocusedElement}
            />
          </div>

          {isEmailCommType(commType) && (
            <div className={styles.footer}>
              <CommFooter
                onClickPersonalize={handlePersonalize}
                isPersonalizeActive={!!commVariablesModalAnchorEl}
              />
            </div>
          )}

          <CommEditStatusModal />
        </form>
      )}
    </Form>
  );
};

export default withComponentLibraryTheme(EditAutomatedCommunication);
