import { Tabs } from "@my-scoot/component-library-legacy";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import { AUTOMATED_COMMS_TABS } from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { AUTOMATED_COMMS_TARGETS } from "features/AutomatedCommunication/constants/AutomatedComms.targets";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";
import React from "react";
import { useForm } from "react-final-form";
import {
  MASTER_CONTROLS_FE_FORM_KEYS,
  MASTER_CONTROLS_FORM_KEYS,
} from "../../constants/MasterControls.constants";
import styles from "./CategoryWiseSettings.module.css";
import CategoryCommsMobileTable from "./modules/CategoryCommsMobileTable/CategoryCommsMobileTable";
import CategoryCommsTable from "./modules/CategoryCommsTable/CategoryCommsTable";

export const CategoryWiseSettings = () => {
  const isDesktop = useIsDesktop();
  const form = useForm();
  const commTarget = useFormFieldValue(MASTER_CONTROLS_FORM_KEYS.sent_to);
  const CategoryComms = isDesktop
    ? CategoryCommsTable
    : CategoryCommsMobileTable;

  const handleTabChange = (e, value) => {
    form.change(MASTER_CONTROLS_FORM_KEYS.sent_to, value);
  };

  return (
    <div className={styles.root}>
      <Tabs
        value={commTarget}
        tabs={AUTOMATED_COMMS_TABS}
        tabVariant="filled_primary"
        classes={{ root: styles.tabsRoot, scroller: "border-none" }}
        onChange={handleTabChange}
      />

      <div
        className={
          commTarget === AUTOMATED_COMMS_TARGETS.SENT_TO_USERS
            ? undefined
            : "d-none"
        }
      >
        <CategoryComms
          name={MASTER_CONTROLS_FE_FORM_KEYS.users_comm_categories}
          commTarget={AUTOMATED_COMMS_TARGETS.SENT_TO_USERS}
        />
      </div>

      <div
        className={
          commTarget === AUTOMATED_COMMS_TARGETS.SENT_TO_CREATOR
            ? undefined
            : "d-none"
        }
      >
        <CategoryComms
          name={MASTER_CONTROLS_FE_FORM_KEYS.creator_comm_categories}
          commTarget={AUTOMATED_COMMS_TARGETS.SENT_TO_CREATOR}
        />
      </div>
    </div>
  );
};
