import { finalFormFieldArrayNameGenerator } from "utils/string";

// automated comms settings modal form keys
export const MASTER_CONTROLS_FORM_KEYS = {
  enabled_communication_mediums: "enabled_communication_mediums", // master setting for enabled communication mediums (controls global comm medium state)
  sent_to: "sent_to", // target audience (enum: AUTOMATED_COMMS_TARGETS)
  name: "name", // general `name` key used for "Communication Title" and "Sender Name" (Email/WhatsApp)
  key: "key", // comm key identifier
  recipient: "recipient", // target recipient (enum: AUTOMATED_COMMS_TARGETS)
  categories: "categories", // comm categories for target audience (AUTOMATED_COMMS_TARGETS)

  // sender management tab keys - start
  sender_management: "sender_management", // parent key for sender management tab data
  email: "email", // sender email
  whatsapp: "whatsapp", // sender whatsapp details
  number: "number", // sender whatsapp number
  use_whitelabel_wa_number: "use_whitelabel_wa_number", // indicates whether to use the creator's personal WhatsApp number
  // sender management tab keys - end
};

// these keys will be used at front-end for react-final-form
export const MASTER_CONTROLS_FE_FORM_KEYS = {
  // master controls tab keys - start
  users_comm_categories: "users_comm_categories", // holds user comm categories data
  creator_comm_categories: "creator_comm_categories", // holds creator comm categories data
  // master controls tab keys - end

  // sender management tab keys - start
  // sender email
  sender_email: finalFormFieldArrayNameGenerator([
    MASTER_CONTROLS_FORM_KEYS.sender_management,
    MASTER_CONTROLS_FORM_KEYS.email,
    MASTER_CONTROLS_FORM_KEYS.email,
  ]),
  // sender name for emails
  sender_email_name: finalFormFieldArrayNameGenerator([
    MASTER_CONTROLS_FORM_KEYS.sender_management,
    MASTER_CONTROLS_FORM_KEYS.email,
    MASTER_CONTROLS_FORM_KEYS.name,
  ]),
  // indicates whether to use the creator's personal WhatsApp number
  sender_use_whitelabel_wa_number: finalFormFieldArrayNameGenerator([
    MASTER_CONTROLS_FORM_KEYS.sender_management,
    MASTER_CONTROLS_FORM_KEYS.whatsapp,
    MASTER_CONTROLS_FORM_KEYS.use_whitelabel_wa_number,
  ]),
  // sender name for whatsapp
  sender_whatsapp_name: finalFormFieldArrayNameGenerator([
    MASTER_CONTROLS_FORM_KEYS.sender_management,
    MASTER_CONTROLS_FORM_KEYS.whatsapp,
    MASTER_CONTROLS_FORM_KEYS.name,
  ]),
  // sender management tab keys - end
};
