import { checkIsArrayEmpty } from "features/Common/modules/DataTypes/utils/Array.utils";
import {
  AUTOMATED_COMM_LISTING_KEYS,
  AUTOMATED_COMMS_TABLE_FE_KEYS,
  AUTOMATED_COMMS_TABLE_KEYS,
  AUTOMATED_COMMS_TABLE_ROW_KEYS,
} from "../constants/AutomatedComms.constants";
import { useAutomatedCommsStore } from "./useAutomatedCommsStore";

// triggered from OFFERING comm to update it's enabled comms mediums
export const handleSetOfferingEnabledComms =
  (set) =>
  ({ commTitle, offeringUuid, enabledComms, targetMedium, activate }) => {
    set((state) => {
      const { commsTitlesState } = state;
      const { [commTitle]: currentCommTitleState = {} } = commsTitlesState;
      const {
        [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_offerings_comms]: offeringComms = {},
      } = currentCommTitleState;
      const { [offeringUuid]: currentOfferingCommState = {} } = offeringComms;

      let updatedCurrentCommTitleState = { ...currentCommTitleState };
      const {
        [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_is_any_listing_comms_disabled]:
          mediumContainingAnyDisbaledOffering = {},
      } = updatedCurrentCommTitleState;
      let updatedMediumContainingAnyDisbaledOffering = {
        ...mediumContainingAnyDisbaledOffering,
      };
      const currentDisableOfferingsCount =
        updatedMediumContainingAnyDisbaledOffering[targetMedium] || 0;
      const disabledOfferingsNewCount = Math.max(
        currentDisableOfferingsCount + (activate ? -1 : 1),
        0
      );
      updatedMediumContainingAnyDisbaledOffering[targetMedium] =
        disabledOfferingsNewCount;

      // enable this medium for parent comm also
      if (activate) {
        const {
          [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_enabled_mediums]:
            currentParentEnabledComms,
        } = updatedCurrentCommTitleState;

        if (!currentParentEnabledComms.includes(targetMedium))
          updatedCurrentCommTitleState[
            AUTOMATED_COMMS_TABLE_FE_KEYS.fe_enabled_mediums
          ] = [...currentParentEnabledComms, targetMedium];
      }

      updatedCurrentCommTitleState = {
        ...updatedCurrentCommTitleState,
        [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_is_any_listing_comms_disabled]:
          updatedMediumContainingAnyDisbaledOffering,
      };

      return {
        ...state,
        commsTitlesState: {
          ...commsTitlesState,
          [commTitle]: {
            ...updatedCurrentCommTitleState,
            [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_offerings_comms]: {
              ...offeringComms,
              [offeringUuid]: {
                ...currentOfferingCommState,
                [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_enabled_mediums]:
                  enabledComms,
              },
            },
          },
        },
      };
    });
  };

export const handleSetParentCommEnabledComms =
  (set) =>
  ({ commTitle, enabledComms, targetMedium, activate }) => {
    set((state) => {
      const { commsTitlesState } = state;
      const { [commTitle]: currentCommTitleState = {} } = commsTitlesState;
      const {
        [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_is_any_listing_comms_disabled]:
          disabledOfferingCountMap,
        [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_total_listing_count]:
          totalListingCount,
      } = currentCommTitleState;
      const updatedOfferingsCountMap = { ...disabledOfferingCountMap };
      updatedOfferingsCountMap[targetMedium] = activate ? 0 : totalListingCount;

      return {
        ...state,
        commsTitlesState: {
          ...commsTitlesState,
          [commTitle]: {
            ...currentCommTitleState,
            [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_is_any_listing_comms_disabled]:
              updatedOfferingsCountMap,
            [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_enabled_mediums]: enabledComms,
          },
        },
      };
    });
  };

// triggered from PARENT comm to initialize it's offerings enabled comms mediums
export const handleInitOfferingsEnabledCommsState =
  (set) =>
  ({ commTitle, offeringsComms }) => {
    set((state) => {
      const { commsTitlesState } = state;
      const { [commTitle]: currentCommTitleState = {} } = commsTitlesState;
      const {
        [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_offerings_comms]:
          currentOfferingComms = {},
      } = currentCommTitleState;
      const updatedOfferingsState = { ...currentOfferingComms };

      offeringsComms.forEach((comm) => {
        const {
          [AUTOMATED_COMM_LISTING_KEYS.has_default_message_content]:
            hasDefaultSettings,
          [AUTOMATED_COMM_LISTING_KEYS.listing_uuid]: offeringsUuid,
          [AUTOMATED_COMM_LISTING_KEYS.enabled_communication_mediums]:
            enabledComms,
        } = comm;
        updatedOfferingsState[offeringsUuid] = {
          [AUTOMATED_COMM_LISTING_KEYS.has_default_message_content]:
            hasDefaultSettings,
          [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_enabled_mediums]: enabledComms,
        };
      });

      return {
        ...state,
        commsTitlesState: {
          ...commsTitlesState,
          [commTitle]: {
            ...currentCommTitleState,
            [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_offerings_comms]:
              updatedOfferingsState,
          },
        },
      };
    });
  };

// triggered from PARENT comm to update it's offerings enabled comms mediums
export const handleChangeOfferingsMedium =
  (set) =>
  ({ commTitle, medium, checked }) => {
    set((state) => {
      const { commsTitlesState } = state;
      const { [commTitle]: currentCommTitleState = {} } = commsTitlesState;
      const {
        [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_offerings_comms]: offeringComms = {},
      } = currentCommTitleState;
      const updatedOfferingsState = { ...offeringComms };

      Object.keys(updatedOfferingsState).forEach((offeringUuid) => {
        const currentOfferingState = updatedOfferingsState[offeringUuid];
        const {
          [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_enabled_mediums]: enabledComms,
        } = currentOfferingState;
        let updatedComms = [...enabledComms];

        if (checked) {
          updatedComms.push(medium);
        } else {
          updatedComms = updatedComms.filter(
            (currentMedium) => currentMedium !== medium
          );
        }

        updatedOfferingsState[offeringUuid] = {
          ...currentOfferingState,
          [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_enabled_mediums]: updatedComms,
        };
      });

      return {
        ...state,
        commsTitlesState: {
          ...commsTitlesState,
          [commTitle]: {
            ...currentCommTitleState,
            [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_offerings_comms]:
              updatedOfferingsState,
          },
        },
      };
    });
  };

export const handleSetCommTitleState =
  (set) =>
  ({ commTitle, values }) => {
    set((state) => {
      const { commsTitlesState } = state;
      const { [commTitle]: currentCommTitleState = {} } = commsTitlesState;

      return {
        ...state,
        commsTitlesState: {
          ...commsTitlesState,
          [commTitle]: {
            ...currentCommTitleState,
            ...values,
          },
        },
      };
    });
  };

const formatDisabledOfferingsCountPayload = (commMediumsOfferingsCounts) => {
  if (
    !commMediumsOfferingsCounts ||
    checkIsArrayEmpty(commMediumsOfferingsCounts)
  )
    return {};

  let countMap = {};

  commMediumsOfferingsCounts.forEach((currentCountMap) => {
    countMap = { ...countMap, ...currentCountMap };
  });

  return countMap;
};

export const handleInitCommTitlesState =
  (set) =>
  ({ tables }) => {
    set((state) => {
      const { commsTitlesState } = state;
      const updatedCommsTitlesState = { ...commsTitlesState };

      tables.forEach((table) => {
        const { [AUTOMATED_COMMS_TABLE_KEYS.comms]: comms } = table;

        comms.forEach((comm) => {
          const {
            [AUTOMATED_COMMS_TABLE_ROW_KEYS.has_default_message_content]:
              hasDefaultSettings,
            [AUTOMATED_COMMS_TABLE_ROW_KEYS.title]: commTitle,
            [AUTOMATED_COMMS_TABLE_ROW_KEYS.enabled_communication_mediums]:
              parentEnabledCommsMediums,
            [AUTOMATED_COMMS_TABLE_KEYS.is_any_listing_comms_disabled]:
              mediumContainingAnyDisbaledOffering = [],
            [AUTOMATED_COMMS_TABLE_KEYS.total_listing_count]: totalListingCount,
          } = comm;

          updatedCommsTitlesState[commTitle] = {
            [AUTOMATED_COMMS_TABLE_ROW_KEYS.has_default_message_content]:
              hasDefaultSettings,
            [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_enabled_mediums]:
              parentEnabledCommsMediums,
            [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_total_listing_count]:
              totalListingCount,
            [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_is_any_listing_comms_disabled]:
              formatDisabledOfferingsCountPayload(
                mediumContainingAnyDisbaledOffering
              ),
          };
        });
      });

      return {
        ...state,
        commsTitlesState: updatedCommsTitlesState,
      };
    });
  };

export const handleUpdateCommEditedStatus =
  (set) =>
  ({ commTitle, offeringUuid, hasDefaultSettings }) => {
    set((state) => {
      const { commsTitlesState } = state;
      const { [commTitle]: currentCommTitleState = {} } = commsTitlesState;
      const {
        [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_offerings_comms]: offeringComms = {},
      } = currentCommTitleState;
      const updatedCommTitleState = { ...currentCommTitleState };

      if (offeringUuid) {
        // handle message edited state for offering comm
        const updatedOfferingsState = { ...offeringComms };
        const currentOfferingState = updatedOfferingsState[offeringUuid];

        updatedOfferingsState[offeringUuid] = {
          ...currentOfferingState,
          [AUTOMATED_COMM_LISTING_KEYS.has_default_message_content]:
            hasDefaultSettings,
        };

        updatedCommTitleState[
          AUTOMATED_COMMS_TABLE_FE_KEYS.fe_offerings_comms
        ] = updatedOfferingsState;
      } else {
        // handle message edited state parent comm
        updatedCommTitleState[
          AUTOMATED_COMMS_TABLE_ROW_KEYS.has_default_message_content
        ] = hasDefaultSettings;
      }

      return {
        ...state,
        commsTitlesState: {
          ...commsTitlesState,
          [commTitle]: updatedCommTitleState,
        },
      };
    });
  };

export const useCommStateValue = ({ commTitle, key }) => {
  return useAutomatedCommsStore((state) => {
    const { commsTitlesState } = state;
    const { [commTitle]: currentCommTitleState = {} } = commsTitlesState;
    return currentCommTitleState[key];
  });
};

export const useParentCommStateValue = ({ commTitle, key }) => {
  return useAutomatedCommsStore((state) => {
    const { commsTitlesState } = state;
    const { [commTitle]: currentCommTitleState = {} } = commsTitlesState;
    return currentCommTitleState[key];
  });
};

export const useOfferingComm = ({ commTitle, offeringUuid }) => {
  return useAutomatedCommsStore((state) => {
    const { commsTitlesState } = state;
    const { [commTitle]: currentCommTitleState = {} } = commsTitlesState;
    const {
      [AUTOMATED_COMMS_TABLE_FE_KEYS.fe_offerings_comms]: offeringComms = {},
    } = currentCommTitleState;
    const { [offeringUuid]: currentOfferingCommState = {} } = offeringComms;
    return currentOfferingCommState;
  });
};

export const handleSetActiveTab = (set) => (activeTab) => {
  set({ activeTab });
};
