import { CircularProgress } from "@material-ui/core";
import { useAutomatedCommsStore } from "features/AutomatedCommunication/store/useAutomatedCommsStore";
import { handleScroll } from "features/AutomatedCommunication/utils/AutomatedCommunication.utils";
import { useAutomatedComms } from "features/AutomatedCommunication/utils/useAutomatedComms";
import React, { useEffect } from "react";
import CommsTable from "../CommsTable/CommsTable";
import { shouldRenderCommsTable } from "./utils/CommsTables.utils";

export const CommsTables = ({ active, commsTarget }) => {
  const {
    isFetching,
    isRefetching,
    isSuccess,
    data: commsTables,
  } = useAutomatedComms({ commsTarget, active });
  const initCommTitlesState = useAutomatedCommsStore(
    (state) => state.initCommTitlesState
  );

  useEffect(() => {
    const handleInitCommTitlesState = () => {
      // initialize comm titles state in zustand store
      // this state enables data manipulation between parent comm and it's offerings comms
      if (!isFetching && isSuccess && commsTables) {
        initCommTitlesState({
          tables: commsTables.filter(shouldRenderCommsTable),
        });

        // scroll to last position left before leaving to edit comm
        if (active) handleScroll();
      }
    };

    handleInitCommTitlesState();
  }, [isFetching, isSuccess]);

  if (isFetching || isRefetching) {
    return (
      <div className="text-center">
        <CircularProgress />
      </div>
    );
  }

  if (commsTables) {
    return commsTables
      .filter(shouldRenderCommsTable)
      .map((table) => <CommsTable key={table.name} table={table} />);
  }

  return null;
};
