import { ExlyRichTextEditor } from "common/form";
import {
  isEmailCommType,
  isSMSCommType,
  isWhatsAppCommType,
} from "features/AutomatedCommunication/utils/AutomatedCommunication.utils";
import { formatCommsVariableForRTE } from "features/Common/modules/Comms/Comms.utils";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";
import React from "react";
import { Field } from "react-final-form";
import { COMMS_FOCUSABLE_ELEMENTS } from "ui/pages/communications/CustomEmail/ComposeEmail/data";
import { renderWhatsappFormatWithVariables } from "ui/pages/whatsappBroadcast/whatsappBroadcast.constants";
import { finalFormFieldArrayNameGenerator } from "utils/string";
import {
  EDIT_COMM_FE_KEYS,
  EDIT_COMM_FORM_KEYS,
} from "../../constants/EditAutomatedCommunication.constants";
import styles from "./CommBody.module.css";
import { WhatsappCommBody } from "./modules/WhatsappCommBody/WhatsappCommBody";

const CommBody = ({
  data,
  commVariablesModalAnchorEl,
  handleCloseCommVariablesModal,
  setLastFocusedElement,
}) => {
  const {
    [EDIT_COMM_FORM_KEYS.email]: { [EDIT_COMM_FORM_KEYS.variables]: variables },
  } = data;

  const commType = useFormFieldValue(EDIT_COMM_FORM_KEYS.comm_type);
  const smsBody = useFormFieldValue(
    finalFormFieldArrayNameGenerator([
      EDIT_COMM_FORM_KEYS.sms,
      EDIT_COMM_FORM_KEYS.body,
    ])
  );

  const handleRTEFocus = (e) => {
    e.stopPropagation();
    setLastFocusedElement(COMMS_FOCUSABLE_ELEMENTS.rte);
  };

  return (
    <>
      {isEmailCommType(commType) && (
        <Field
          name={EDIT_COMM_FE_KEYS.fe_email_body}
          component={ExlyRichTextEditor}
          rootWrapperClassName="border-none"
          customToolbarClassName={styles.toolbar}
          editableWrapperClassName={styles.rte}
          editableProps={{
            onFocus: handleRTEFocus,
          }}
          variablesProps={{
            anchorEl: commVariablesModalAnchorEl,
            chips: formatCommsVariableForRTE(Object.values(variables)),
            onClose: handleCloseCommVariablesModal,
            classes: {
              rootClassName: styles.variablesRoot,
            },
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            ModalProps: {
              disablePortal: false,
            },
          }}
        />
      )}

      {isWhatsAppCommType(commType) && <WhatsappCommBody />}

      {isSMSCommType(commType) && (
        <div
          className={styles.body}
          dangerouslySetInnerHTML={{
            __html: renderWhatsappFormatWithVariables({})(smsBody),
          }}
        />
      )}
    </>
  );
};

export default CommBody;
