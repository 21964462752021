import React from "react";
import { Route } from "react-router-dom";
import { LazyLoadRoutes } from "routes/lazyLoadRoutes";
import AutomatedCommunication from "../AutomatedCommunication";

export const AUTOMATED_COMMS_CUSTOM_ROUTE_KEYS = {
  AUTOMATED_COMMS: "AUTOMATED_COMMS",
};

export const AUTOMATED_COMMS_ROUTE_IDS = {
  [AUTOMATED_COMMS_CUSTOM_ROUTE_KEYS.AUTOMATED_COMMS]:
    "/notifications/automated-messages",
};

export const AUTOMATED_COMMS_MAIN_PATH =
  AUTOMATED_COMMS_ROUTE_IDS[AUTOMATED_COMMS_CUSTOM_ROUTE_KEYS.AUTOMATED_COMMS];

export const AUTOMATED_COMMS_CUSTOM_ROUTES = [
  <Route
    exact
    key={
      AUTOMATED_COMMS_ROUTE_IDS[
        AUTOMATED_COMMS_CUSTOM_ROUTE_KEYS.AUTOMATED_COMMS
      ]
    }
    path={
      AUTOMATED_COMMS_ROUTE_IDS[
        AUTOMATED_COMMS_CUSTOM_ROUTE_KEYS.AUTOMATED_COMMS
      ]
    }
    render={(props) => (
      <LazyLoadRoutes LazyElement={AutomatedCommunication} props={props} />
    )}
  />,
];
