import { getRAUrlParams } from "features/Common/utils/url.utils";
import { ApprovalAwatingModal } from "features/CreateWhatsappPresetNotificationTemplate/modules/ApprovalAwatingModal/ApprovalAwatingModal";
import React from "react";
import { useToggleState } from "utils/hooks";
import { EDIT_COMM_URL_PARAMS } from "../../constants/EditAutomatedCommunication.constants";

export const CommEditStatusModal = () => {
  const urlParams = getRAUrlParams();
  const commEditStatus = urlParams.get(EDIT_COMM_URL_PARAMS.ces);
  const [isStatusModalOpen, , closeStatusModal] = useToggleState(
    !!commEditStatus
  );

  return (
    <ApprovalAwatingModal
      open={isStatusModalOpen}
      onClose={closeStatusModal}
      onPrimaryBtnClick={closeStatusModal}
    />
  );
};
