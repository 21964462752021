import { COMMS_TYPES_COLUMN_ORDER } from "features/AutomatedCommunication/constants/AutomatedComms.constants";

export const hasCommType = (allowedCommTypes, commTypeToCheck) =>
  allowedCommTypes?.some((type) => type === commTypeToCheck);

export const isIndeteminate = ({
  disabledOfferingsCountMap,
  commTypeToCheck,
  totalListingCount,
}) => {
  if (!disabledOfferingsCountMap) return undefined;

  const count = disabledOfferingsCountMap[commTypeToCheck];

  if (!count) return undefined;

  return (count > 0 && count < totalListingCount) || undefined;
};

export const getCommColumns = ({
  enabledCommsMediums,
  allowedCommsMediums,
  mediumContainingAnyDisbaledOffering,
  onChangeGetter,
  totalListingCount,
}) =>
  COMMS_TYPES_COLUMN_ORDER.map((type) => ({
    type,
    checked: hasCommType(enabledCommsMediums, type),
    disabled: !hasCommType(allowedCommsMediums, type),
    indeterminate: isIndeteminate({
      disabledOfferingsCountMap: mediumContainingAnyDisbaledOffering,
      commTypeToCheck: type,
      totalListingCount,
    }),
    onChange: onChangeGetter(type),
  }));
