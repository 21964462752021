import { AUTOMATED_COMMS_TARGETS } from "features/AutomatedCommunication/constants/AutomatedComms.targets";
import { checkIsArrayEmpty } from "features/Common/modules/DataTypes/utils/Array.utils";
import { is_empty } from "features/Common/utils/common.utils";
import { logError } from "utils/error";
import {
  MASTER_CONTROLS_FE_FORM_KEYS,
  MASTER_CONTROLS_FORM_KEYS,
} from "../modules/MasterControls/constants/MasterControls.constants";
import {
  saveAutomatedCommsMasterControls,
  saveAutomatedCommsSenderDetails,
} from "./CommsSettingsModal.apiCalls";
import { HTTP_STATUS_SUCCESS_CODES } from "data/api.constants";
import { checkIsDefined } from "features/Common/modules/DataTypes/utils/nanNullUndef";

// format comm category data for api payload
const formatCategoryComms = ({ comms, recipient }) => {
  if (checkIsArrayEmpty(comms)) return [];

  return comms.map((comm) => {
    const {
      [MASTER_CONTROLS_FORM_KEYS.key]: key,
      [MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums]: mediums,
    } = comm;

    return {
      [MASTER_CONTROLS_FORM_KEYS.key]: key,
      [MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums]: mediums,
      [MASTER_CONTROLS_FORM_KEYS.recipient]: recipient, // attach target audience
    };
  });
};

// format and filter form data for master controls
export const sanitizeMasterControlsPayload = (formData) => {
  const {
    [MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums]: mediums,
    [MASTER_CONTROLS_FE_FORM_KEYS.users_comm_categories]: userCategoryComms,
    [MASTER_CONTROLS_FE_FORM_KEYS.creator_comm_categories]:
      creatorCategoryComms,
  } = formData;

  let commsCategories = []; // contains comm categories data for all audience targets

  // format user category comms
  const usersCategoriesComms = formatCategoryComms({
    comms: userCategoryComms,
    recipient: AUTOMATED_COMMS_TARGETS.SENT_TO_USERS,
  });

  // format creator category comms
  const creatorCategoriesComms = formatCategoryComms({
    comms: creatorCategoryComms,
    recipient: AUTOMATED_COMMS_TARGETS.SENT_TO_CREATOR,
  });

  // add only updated category comms
  if (!checkIsArrayEmpty(usersCategoriesComms)) {
    commsCategories = [...commsCategories, ...usersCategoriesComms];
  }
  if (!checkIsArrayEmpty(creatorCategoriesComms)) {
    commsCategories = [...commsCategories, ...creatorCategoriesComms];
  }

  const payload = {};

  // add global mediums state if changed to api payload
  if (!is_empty(mediums)) {
    payload[MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums] = mediums;
  }

  // add category comms data if changed to api payload
  if (!checkIsArrayEmpty(commsCategories)) {
    payload[MASTER_CONTROLS_FORM_KEYS.categories] = commsCategories;
  }

  return is_empty(payload) ? undefined : payload;
};

// format and filter sender details data for api payload
export const sanitizeSenderDetailsPayload = (formData) => {
  const { [MASTER_CONTROLS_FORM_KEYS.sender_management]: senderManagement } =
    formData;

  if (is_empty(senderManagement)) return undefined; // no changes in sender details

  const {
    [MASTER_CONTROLS_FORM_KEYS.email]: {
      [MASTER_CONTROLS_FORM_KEYS.name]: emailName,
      [MASTER_CONTROLS_FORM_KEYS.email]: email,
    } = {},
    [MASTER_CONTROLS_FORM_KEYS.whatsapp]: {
      [MASTER_CONTROLS_FORM_KEYS.name]: whatsappName,
      [MASTER_CONTROLS_FORM_KEYS.use_whitelabel_wa_number]: usePersonalNumber,
    } = {},
  } = senderManagement;

  const payload = {};

  // add sender name for email if changed to payload
  if (emailName) {
    payload[MASTER_CONTROLS_FORM_KEYS.email] = {
      [MASTER_CONTROLS_FORM_KEYS.name]: emailName,
    };
  }

  // add sender email if changed to payload
  if (email) {
    payload[MASTER_CONTROLS_FORM_KEYS.email] = {
      ...payload[MASTER_CONTROLS_FORM_KEYS.email],
      [MASTER_CONTROLS_FORM_KEYS.email]: email,
    };
  }

  // set use_whitelabel_wa_number if personal whatsapp number enabled for automated comms
  if (checkIsDefined(usePersonalNumber)) {
    payload[MASTER_CONTROLS_FORM_KEYS.whatsapp] = {
      [MASTER_CONTROLS_FORM_KEYS.use_whitelabel_wa_number]: usePersonalNumber,
    };
  }

  // add sender name for whatsapp if changed to payload
  if (whatsappName) {
    payload[MASTER_CONTROLS_FORM_KEYS.whatsapp] = {
      ...payload[MASTER_CONTROLS_FORM_KEYS.whatsapp],
      [MASTER_CONTROLS_FORM_KEYS.name]: whatsappName,
    };
  }

  return is_empty(payload) ? undefined : payload;
};

export const saveAutomatedCommsSettings = (formData) => {
  const masterControlsPayload = sanitizeMasterControlsPayload(formData);
  const senderManagementPayload = sanitizeSenderDetailsPayload(formData);
  const promises = [];

  if (!is_empty(masterControlsPayload)) {
    promises.push(saveAutomatedCommsMasterControls(masterControlsPayload));
  }

  if (!is_empty(senderManagementPayload)) {
    promises.push(saveAutomatedCommsSenderDetails(senderManagementPayload));
  }

  // if not settings changed
  if (checkIsArrayEmpty(promises)) {
    return new Promise((res) =>
      res(HTTP_STATUS_SUCCESS_CODES.HTTP_STATUS_NOT_MODIFIED)
    );
  }

  return Promise.allSettled(promises).then((responses) => {
    const [masterControlsResponse, senderDetailsResponse] = responses;

    if (masterControlsResponse?.status === "rejected") {
      logError({
        error: masterControlsResponse.reason?.message,
        when: "saving automated comms master controls settings",
        occuredAt:
          "src/features/AutomatedCommunication/modules/CommsSettingsModal/utils/CommsSettingsModal.utils.js",
        severity: "blocker",
      });
      throw new Error(masterControlsResponse.reason?.message);
    }

    if (senderDetailsResponse?.status === "rejected") {
      logError({
        error: senderDetailsResponse.reason?.message,
        when: "saving automated comms sender details settings",
        occuredAt:
          "src/features/AutomatedCommunication/modules/CommsSettingsModal/utils/CommsSettingsModal.utils.js",
        severity: "blocker",
      });
      throw new Error(senderDetailsResponse.reason?.message);
    }
  });
};

// check if there is no change between the initial and current comm mediums selection
export const isCommsEqual = (initialSelectedComms, currentSelectedComms) => {
  // if number of current selected comms mediums is different than initial selected comms mediums, return false
  if (initialSelectedComms?.length !== currentSelectedComms?.length)
    return false;

  const initialSelectedCommsSet = new Set(initialSelectedComms);

  // if number of current selected comms mediums is same as initial selected comms mediums, check if all current selected comms mediums are present in initial selected comms mediums
  return currentSelectedComms.every((comm) =>
    initialSelectedCommsSet.has(comm)
  );
};

// check if there is no change between the initial and current comm mediums selection for category comm
export const isCommCategoryEqual = (
  initialCategoriesData,
  currentCategoriesData
) => {
  if (!currentCategoriesData && !currentCategoriesData) return true;

  if (initialCategoriesData?.length !== currentCategoriesData?.length)
    return false;

  const isAnyCategoryDataChanged = initialCategoriesData.some(
    (
      {
        [MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums]:
          currentCategoryInitialEnabledComms,
      },
      index
    ) => {
      const {
        [MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums]:
          currentCategoryEnabledComms,
      } = currentCategoriesData[index];

      return !isCommsEqual(
        currentCategoryInitialEnabledComms,
        currentCategoryEnabledComms
      );
    }
  );

  return !isAnyCategoryDataChanged;
};

const getDirtyCategoryData = (key, form) => {
  const commCategoryFieldState = form.getFieldState(key);
  const categoryComms = [];
  if (commCategoryFieldState?.dirty) {
    commCategoryFieldState.value.forEach((_, index) => {
      const { dirty: isEnabledCommsDirty, value: currentCategoryData } =
        form.getFieldState(`${key}[${index}]`);
      if (isEnabledCommsDirty) categoryComms.push(currentCategoryData);
    });
  }
  return categoryComms;
};

const getDirtyMasterSettings = (form) => {
  const { dirty, value } = form.getFieldState(
    MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums
  );

  return dirty ? value : undefined;
};

const getDirtySenderManagementSettings = (form) => {
  const senderEmailFieldState = form.getFieldState(
    MASTER_CONTROLS_FE_FORM_KEYS.sender_email
  );
  const senderEmailNameFieldState = form.getFieldState(
    MASTER_CONTROLS_FE_FORM_KEYS.sender_email_name
  );
  const senderUseWhitelabelNumberFieldState = form.getFieldState(
    MASTER_CONTROLS_FE_FORM_KEYS.sender_use_whitelabel_wa_number
  );
  const senderWhatsappNameFieldState = form.getFieldState(
    MASTER_CONTROLS_FE_FORM_KEYS.sender_whatsapp_name
  );

  const senderManagement = {};

  if (senderEmailFieldState.dirty) {
    senderManagement[MASTER_CONTROLS_FORM_KEYS.email] = {
      [MASTER_CONTROLS_FORM_KEYS.email]: senderEmailFieldState.value,
    };
  }
  if (senderEmailNameFieldState.dirty) {
    senderManagement[MASTER_CONTROLS_FORM_KEYS.email] = {
      ...senderManagement[MASTER_CONTROLS_FORM_KEYS.email],
      [MASTER_CONTROLS_FORM_KEYS.name]: senderEmailNameFieldState.value,
    };
  }
  if (senderUseWhitelabelNumberFieldState.dirty) {
    senderManagement[MASTER_CONTROLS_FORM_KEYS.whatsapp] = {
      [MASTER_CONTROLS_FORM_KEYS.use_whitelabel_wa_number]:
        senderUseWhitelabelNumberFieldState.value,
    };
  }
  if (senderWhatsappNameFieldState.dirty) {
    senderManagement[MASTER_CONTROLS_FORM_KEYS.whatsapp] = {
      ...senderManagement[MASTER_CONTROLS_FORM_KEYS.whatsapp],
      [MASTER_CONTROLS_FORM_KEYS.name]: senderWhatsappNameFieldState.value,
    };
  }

  return is_empty(senderManagement) ? undefined : senderManagement;
};

export const getDirtySettings = (formData, form) => {
  return {
    ...formData,
    [MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums]:
      getDirtyMasterSettings(form),
    [MASTER_CONTROLS_FE_FORM_KEYS.users_comm_categories]: getDirtyCategoryData(
      MASTER_CONTROLS_FE_FORM_KEYS.users_comm_categories,
      form
    ),
    [MASTER_CONTROLS_FE_FORM_KEYS.creator_comm_categories]:
      getDirtyCategoryData(
        MASTER_CONTROLS_FE_FORM_KEYS.creator_comm_categories,
        form
      ),
    [MASTER_CONTROLS_FORM_KEYS.sender_management]:
      getDirtySenderManagementSettings(form),
  };
};
