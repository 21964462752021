import { useCustomMutation } from "data/modules/api/hooks/useCustomMutation";
import { AUTOMATED_COMMS_APIS } from "features/AutomatedCommunication/constants/AutomatedComms.api";
import {
  AUTOMATED_COMM_LISTING_KEYS,
  AUTOMATED_COMM_UPDATE_PAYLOAD_KEYS,
  AUTOMATED_COMMS_TABLE_FE_KEYS,
  AUTOMATED_COMMS_TABLE_ROW_KEYS,
} from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { AUTOMATED_COMMS_URL_PARAMS } from "features/AutomatedCommunication/constants/AutomatedComms.urlParams";
import { useAutomatedCommsStore } from "features/AutomatedCommunication/store/useAutomatedCommsStore";
import {
  useCommStateValue,
  useParentCommStateValue,
} from "features/AutomatedCommunication/store/useAutomatedCommsStore.utils";
import {
  canEditComm,
  isTargetCommCreator,
} from "features/AutomatedCommunication/utils/AutomatedCommunication.utils";
import React from "react";
import { logError } from "utils/error";
import {
  notification_color_keys,
  useNotifications,
  useToggleState,
} from "utils/hooks";
import CommListings from "./modules/CommListings/CommListings";
import { CommMainRow } from "./modules/CommMainRow/CommMainRow";
import { getCommColumns } from "./utils/CommRow.utils";

export const CommRow = ({ comm, categoryKey, onReset, onEdit }) => {
  const { notify } = useNotifications();
  const {
    [AUTOMATED_COMMS_TABLE_ROW_KEYS.title]: commTitle,
    [AUTOMATED_COMMS_TABLE_ROW_KEYS.cdb_type]: cdbType,
    [AUTOMATED_COMMS_TABLE_ROW_KEYS.trigger]: commInfo,
    [AUTOMATED_COMMS_TABLE_ROW_KEYS.total_listing_count]: totalListingCount,
    [AUTOMATED_COMMS_TABLE_ROW_KEYS.allowed_communication_mediums]:
      parentAllowedCommsMediums,
  } = comm;
  const { mutate: toggleCommTypeState } = useCustomMutation({
    url: AUTOMATED_COMMS_APIS.update_comm_type_enabled_state(commTitle),
    onError: () => {},
  });
  const [expanded, , , toggleExpand] = useToggleState(false);
  const enabledComms =
    useParentCommStateValue({
      commTitle,
      key: AUTOMATED_COMMS_TABLE_FE_KEYS.fe_enabled_mediums,
    }) ?? [];
  const setParentCommEnabledComms = useAutomatedCommsStore(
    (state) => state.setParentCommEnabledComms
  );
  const setOfferingsEnabledMedium = useAutomatedCommsStore(
    (state) => state.setOfferingsEnabledMedium
  );
  const activeTab = useAutomatedCommsStore((state) => state.activeTab);

  const mediumContainingAnyDisbaledOffering = useCommStateValue({
    commTitle,
    key: AUTOMATED_COMMS_TABLE_FE_KEYS.fe_is_any_listing_comms_disabled,
  });
  const hasDefaultSettings = useCommStateValue({
    commTitle,
    key: AUTOMATED_COMMS_TABLE_ROW_KEYS.has_default_message_content,
  });

  const handleToggleCommTypeActiveState = (inputCommType) => (activate) => {
    const prevEnabledComms = [...enabledComms];

    const handleRevert = () => {
      setParentCommEnabledComms({
        commTitle,
        enabledComms: prevEnabledComms,
        targetMedium: inputCommType,
        activate,
      });
      setOfferingsEnabledMedium({
        commTitle,
        medium: inputCommType,
        checked: !activate,
      });
    };

    const handleError = (error) => {
      handleRevert();
      logError({
        error,
        when: "updating automated comms parent comm",
        severity: "blocker",
        occuredAt:
          "src/features/AutomatedCommunication/modules/CommsTable/modules/CommRow/CommRow.jsx",
      });
      notify(
        "Something went wrong, please try again",
        notification_color_keys.error
      );
    };

    let updatedEnabledComms = [...enabledComms];

    if (activate) {
      // enable comm type
      updatedEnabledComms.push(inputCommType);
    } else {
      // disable comm type
      updatedEnabledComms = updatedEnabledComms.filter(
        (commType) => commType !== inputCommType
      );
    }

    setOfferingsEnabledMedium({
      commTitle,
      medium: inputCommType,
      checked: activate,
    });
    setParentCommEnabledComms({
      commTitle,
      enabledComms: updatedEnabledComms,
      targetMedium: inputCommType,
      activate,
    });
    toggleCommTypeState(
      {
        [AUTOMATED_COMM_UPDATE_PAYLOAD_KEYS.enabled_communication_mediums]: [
          { [inputCommType]: activate },
        ],
      },
      { onError: handleError }
    );
  };

  const handleResetParentComm = () => {
    onReset({ commTitle });
  };

  const handleEditComm = (params = {}) => {
    const { [AUTOMATED_COMM_LISTING_KEYS.listing_uuid]: listingUuid } = params;

    onEdit({
      [AUTOMATED_COMMS_URL_PARAMS.category]: categoryKey,
      [AUTOMATED_COMMS_URL_PARAMS.comm_title]: commTitle,
      [AUTOMATED_COMMS_URL_PARAMS.comm_info]: cdbType,
      [AUTOMATED_COMMS_URL_PARAMS.listing_uuid]: listingUuid,
    });
  };

  const commsColumns = getCommColumns({
    allowedCommsMediums: parentAllowedCommsMediums,
    enabledCommsMediums: enabledComms,
    mediumContainingAnyDisbaledOffering,
    totalListingCount,
    onChangeGetter: handleToggleCommTypeActiveState,
  });

  return (
    <>
      <CommMainRow
        title={cdbType}
        info={commInfo}
        expanded={expanded}
        onExpand={totalListingCount ? toggleExpand : undefined}
        commsColumns={commsColumns}
        onReset={handleResetParentComm}
        onEdit={handleEditComm}
        showReset={!hasDefaultSettings}
        showEdit={
          canEditComm(parentAllowedCommsMediums) &&
          !isTargetCommCreator(activeTab)
        }
      />

      <CommListings
        commTitle={commTitle}
        onReset={onReset}
        onEdit={handleEditComm}
        enabled={expanded}
      />
    </>
  );
};
