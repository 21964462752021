import { isCommCategoryEqual } from "features/AutomatedCommunication/modules/CommsSettingsModal/utils/CommsSettingsModal.utils";
import React from "react";
import { FieldArray } from "react-final-form-arrays";
import { useManageMasterSettings } from "../../utils/useManageMasterSettings";
import styles from "./CategoryCommsMobileTable.module.css";
import MobileCategoryComm from "./modules/MobileCategoryComm/MobileCategoryComm";

const CategoryCommsMobileTable = ({ commTarget, name }) => {
  const { generateCommActiveStateChangeHandler } = useManageMasterSettings({
    commTarget,
    name,
  });

  return (
    <div className={styles.root}>
      <FieldArray name={name} isEqual={isCommCategoryEqual}>
        {({ fields }) =>
          fields.map((name, index) => (
            <MobileCategoryComm
              key={name}
              name={name}
              comm={fields.value[index]}
              onChange={generateCommActiveStateChangeHandler(index)}
            />
          ))
        }
      </FieldArray>
    </div>
  );
};

export default CategoryCommsMobileTable;
