import { Drawer, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import classnames from "classnames";
import React from "react";
import styles from "./CommDesktopPreview.module.css";

const CommDesktopPreview = ({ title, open, onClose, children }) => {
  return (
    <Drawer open={open} onClose={onClose} anchor="right">
      <div
        className={classnames(
          "d-flex align-items-center justify-content-between",
          styles.header
        )}
      >
        <div>{title}</div>

        <IconButton className="p-0" onClick={onClose}>
          <Close />
        </IconButton>
      </div>

      <div className={styles.body}>{children}</div>
    </Drawer>
  );
};

export default CommDesktopPreview;
