import { CircularProgress } from "@material-ui/core";
import classnames from "classnames";
import ExlyImage from "common/Components/ExlyImage";
import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";
import React from "react";
import { useWhatsappProfile } from "ui/pages/whatsappBroadcast/Components/ProfilePreviewDetails/utils/useWhatsappProfile";
import styles from "./WhatsAppMobilePlaceholder.module.css";

const WhatsAppMobilePlaceholder = ({ classes = {}, children }) => {
  const { isLoadingProfile, profilePic, name } = useWhatsappProfile();

  return (
    <div className={classnames(styles.root, classes.root)}>
      <div className={styles.screen}>
        <div className={classnames("d-flex align-items-center", styles.header)}>
          <div className="position-relative">
            {profilePic && <ExlyImage src={profilePic} alt="profile pic" />}
            {isLoadingProfile && (
              <div
                className={classnames(
                  "d-flex position-absolute",
                  styles.profileLoaderContainer
                )}
              >
                <CircularProgress className={styles.loader} />
              </div>
            )}
          </div>
          {name}
        </div>

        {children && (
          <div
            className={classnames(
              styles.templateContent,
              classes.templateContent
            )}
          >
            {children}
            <ExlyImage
              className={styles.chatBubbleArrow}
              src={getS3AssetCDNUrl(
                "assets/vectors/common/chat_bubble_arrow.svg"
              )}
              alt=""
            />
          </div>
        )}
      </div>

      <ExlyImage
        className={styles.frame}
        src={getS3AssetCDNUrl("assets/vectors/common/mobile_frame.svg")}
        alt="template"
      />
    </div>
  );
};

export default WhatsAppMobilePlaceholder;
