import { ChevronLeftRounded, ChevronRight } from "@material-ui/icons";
import exlyIcon from "assets/images/exly-whatsapp-dp.jpg";
import classnames from "classnames";
import ExlyImage from "common/Components/ExlyImage";
import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";
import React from "react";
import styles from "./SMSMobileFrame.module.css";

const SMSMobileFrame = ({ children }) => {
  return (
    <div className={styles.root}>
      <div className={styles.screen}>
        <div
          className={classnames(
            "d-flex justify-content-between",
            styles.header
          )}
        >
          <ChevronLeftRounded className={styles.backCta} />
          <div className={styles.logoContainer}>
            <div>
              <ExlyImage
                className={styles.profile}
                src={exlyIcon}
                alt="profile pic"
              />
            </div>
            <div
              className={classnames(
                "d-flex align-items-center justify-content-center",
                styles.logo
              )}
            >
              Exly <ChevronRight />
            </div>
          </div>
          <div></div>
        </div>

        <div className={styles.body}>
          <div className={classnames("text-center", styles.receivedAt)}>
            Yesterday 9:41
          </div>

          <div className={styles.message}>
            {children}
            <ExlyImage
              className={styles.chatBubbleArrow}
              src={getS3AssetCDNUrl(
                "assets/vectors/common/chat_bubble_arrow.svg"
              )}
              alt=""
            />
          </div>
        </div>
      </div>

      <ExlyImage
        className={styles.frame}
        src={getS3AssetCDNUrl("assets/vectors/common/mobile_frame.svg")}
        alt="template"
      />
    </div>
  );
};

export default SMSMobileFrame;
