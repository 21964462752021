import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import { AUTOMATED_COMMS_TABLE_KEYS } from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { handleSetLastScrollPosition } from "features/AutomatedCommunication/utils/AutomatedCommunication.utils";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { appendUrlParams } from "utils/urlUtils";
import {
  EDIT_AUTOMATED_COMM_ROUTE_IDS,
  EDIT_AUTOMATED_COMM_ROUTE_KEYS,
} from "../EditAutomatedCommunication/constants/EditAutomatedCommunication.urlPaths";
import styles from "./CommsTable.module.css";
import DesktopCommsTable from "./modules/DesktopCommsTable/DesktopCommsTable";
import MobileCommsTable from "./modules/MobileCommsTable/MobileCommsTable";
import ResetCommPopup from "./modules/ResetCommPopup/ResetCommPopup";

const CommsTable = ({ table }) => {
  const isDesktop = useIsDesktop();
  const history = useHistory();
  const [resetModalState, setResetModalState] = useState();
  const isResetModalOpen = !!resetModalState;
  const { [AUTOMATED_COMMS_TABLE_KEYS.name]: tableName } = table;
  const Table = isDesktop ? DesktopCommsTable : MobileCommsTable;

  const handleEditComm = (params) => {
    history.push(
      appendUrlParams(
        EDIT_AUTOMATED_COMM_ROUTE_IDS[
          EDIT_AUTOMATED_COMM_ROUTE_KEYS.EDIT_AUTOMATED_COMM
        ],
        params
      )
    );
    handleSetLastScrollPosition();
  };

  const handleOpenResetCommModal = (data) => {
    setResetModalState(data);
  };

  const handleCloseResetCommModal = () => {
    setResetModalState();
  };

  return (
    <div>
      <div className={styles.tableTitle}>{tableName}</div>

      <Table
        onEdit={handleEditComm}
        onReset={handleOpenResetCommModal}
        table={table}
      />

      {isResetModalOpen && (
        <ResetCommPopup
          open={isResetModalOpen}
          onClose={handleCloseResetCommModal}
          resetData={resetModalState}
        />
      )}
    </div>
  );
};

export default CommsTable;
