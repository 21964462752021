import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import { TEMPLATE_STEPS } from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";
import { redirectToCreatePresetNotificationTemplates } from "features/CreateWhatsappPresetNotificationTemplate/utils/CreateWhatsappPresetNotificationTemplate.utils";
import React from "react";
import { useForm } from "react-final-form";
import { useHistory } from "react-router-dom";
import { EDIT_COMM_FE_KEYS } from "../../constants/EditAutomatedCommunication.constants";
import InfoAction from "../InfoAction/InfoAction";
import styles from "./EditWhatsAppMessageAction.module.css";

const EditWhatsAppMessageAction = ({ commTitle }) => {
  const history = useHistory();
  const isDesktop = useIsDesktop();
  const form = useForm();

  const handleEditWhatsAppMessage = () => {
    const templateUuid = form.getFieldState(
      EDIT_COMM_FE_KEYS.fe_whatsapp_template_uuid
    ).value;
    redirectToCreatePresetNotificationTemplates({
      template: templateUuid,
      history,
      step: TEMPLATE_STEPS.TEMPLATE_DETAILS,
      redirectUrlOnSuccess: window.location.href,
      parentCommTitle: commTitle,
    });
  };

  return (
    <InfoAction
      info='Want to make minor changes to this message? Click on "Edit Message" to duplicate this template, make minor changes, and submit it for approval!'
      actionText="Edit message"
      onActionClick={handleEditWhatsAppMessage}
      classes={{ root: styles.root }}
      actionProps={{ fullWidth: !isDesktop }}
    />
  );
};

export default EditWhatsAppMessageAction;
