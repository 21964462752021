import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import useAppLayoutControl from "hooks/useAppLayoutControl";
import React from "react";
import EditCommsMobileHeader from "../EditCommPageHeader/modules/EditCommsMobileHeader/EditCommsMobileHeader";

export const MobileHeader = ({ onSave, submitting, commType }) => {
  const isDesktop = useIsDesktop();

  useAppLayoutControl({
    values: {
      title: (
        <EditCommsMobileHeader
          onSave={onSave}
          isSubmitting={submitting}
          commType={commType}
        />
      ),
      showBottomNav: false,
    },
    control: {
      appBar: !isDesktop,
      bottomNav: !isDesktop,
      title: !isDesktop,
    },
  });

  return null;
};
