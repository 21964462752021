import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/EditOutlined";
import ResetIcon from "@material-ui/icons/HistoryOutlined";
import { Checkbox } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import { useCustomMutation } from "data/modules/api/hooks/useCustomMutation";
import { AUTOMATED_COMMS_APIS } from "features/AutomatedCommunication/constants/AutomatedComms.api";
import {
  AUTOMATED_COMM_LISTING_KEYS,
  AUTOMATED_COMM_LISTING_UPDATE_PAYLOAD_KEYS,
  AUTOMATED_COMMS_TABLE_FE_KEYS,
} from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { useAutomatedCommsStore } from "features/AutomatedCommunication/store/useAutomatedCommsStore";
import { useOfferingComm } from "features/AutomatedCommunication/store/useAutomatedCommsStore.utils";
import {
  canEditComm,
  isTargetCommCreator,
} from "features/AutomatedCommunication/utils/AutomatedCommunication.utils";
import React from "react";
import { logError } from "utils/error";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { getCommColumns } from "../../../CommRow/utils/CommRow.utils";
import { MediumCheckboxWrapper } from "../../../MediumCheckboxWrapper/MediumCheckboxWrapper";
import styles from "./OfferingCommCard.module.css";

const OfferingCommCard = ({ commTitle, comm, onReset, onEdit }) => {
  const { notify } = useNotifications();
  const {
    [AUTOMATED_COMM_LISTING_KEYS.title]: title,
    [AUTOMATED_COMM_LISTING_KEYS.allowed_communication_mediums]:
      allowedCommsMediums,
    [AUTOMATED_COMM_LISTING_KEYS.listing_uuid]: listingUuid,
  } = comm;
  const { mutate: toggleCommTypeState } = useCustomMutation({
    url: AUTOMATED_COMMS_APIS.update_comm_type_enabled_state(commTitle),
    onError: () => {},
  });
  const setOfferingEnabledComms = useAutomatedCommsStore(
    (state) => state.setOfferingEnabledComms
  );
  const offeringCommData =
    useOfferingComm({
      commTitle,
      offeringUuid: listingUuid,
    }) ?? {};
  const enabledComms =
    offeringCommData[AUTOMATED_COMMS_TABLE_FE_KEYS.fe_enabled_mediums] ?? [];
  const hasDefaultSettings =
    !!offeringCommData[AUTOMATED_COMM_LISTING_KEYS.has_default_message_content];
  const activeTab = useAutomatedCommsStore((state) => state.activeTab);
  const canEdit =
    canEditComm(allowedCommsMediums) && !isTargetCommCreator(activeTab);

  const handleToggleCommTypeActiveState = (inputCommType) => (activate) => {
    const prevEnabledComms = [...enabledComms];

    const handleRevert = () => {
      setOfferingEnabledComms({
        commTitle,
        offeringUuid: listingUuid,
        enabledComms: prevEnabledComms,
        targetMedium: inputCommType,
        activate: !activate,
      });
    };

    const handleError = (error) => {
      handleRevert();
      logError({
        error,
        when: "updating automated comms offering comm",
        severity: "blocker",
        occuredAt:
          "src/features/AutomatedCommunication/modules/CommsTable/modules/EditCommOfferingsPopup/modules/OfferingCommCard/OfferingCommCard.jsx",
      });
      notify(
        "Something went wrong, please try again",
        notification_color_keys.error
      );
    };

    let updatedEnabledComms = [...enabledComms];

    if (activate) {
      // enable comm type
      updatedEnabledComms.push(inputCommType);
    } else {
      // disable comm type
      updatedEnabledComms = updatedEnabledComms.filter(
        (commType) => commType !== inputCommType
      );
    }

    // setEnabledComms(updatedEnabledComms);
    setOfferingEnabledComms({
      commTitle,
      offeringUuid: listingUuid,
      enabledComms: updatedEnabledComms,
      targetMedium: inputCommType,
      activate,
    });

    toggleCommTypeState(
      {
        listings: [
          {
            [AUTOMATED_COMM_LISTING_UPDATE_PAYLOAD_KEYS.uuid]: listingUuid,
            [AUTOMATED_COMM_LISTING_UPDATE_PAYLOAD_KEYS.enabled_communication_mediums]:
              updatedEnabledComms,
          },
        ],
      },
      { onError: handleError }
    );
  };

  const commsColumns = getCommColumns({
    allowedCommsMediums,
    enabledCommsMediums: enabledComms,
    onChangeGetter: handleToggleCommTypeActiveState,
  });

  const actions = [
    {
      id: 1,
      icon: EditIcon,
      onClick: onEdit,
      hide: !canEdit,
    },
    { id: 2, icon: ResetIcon, onClick: onReset, hide: hasDefaultSettings },
  ];

  return (
    <div className={styles.root}>
      <div
        className={classnames(
          "d-flex align-items-center justify-content-between",
          styles.header
        )}
      >
        <div className={styles.label}>{title}</div>

        <div
          className={classnames(
            "d-flex align-items-center justify-content-between",
            styles.actionsContainer
          )}
        >
          {actions.map(
            ({ id, icon: Icon, hide, onClick }) =>
              !hide && (
                <IconButton
                  key={id}
                  className={classnames("p-0", styles.actionCta)}
                  onClick={onClick}
                >
                  <Icon />
                </IconButton>
              )
          )}
        </div>
      </div>

      <div
        className={classnames(
          "d-flex align-items-center justify-content-between",
          styles.body
        )}
      >
        {commsColumns.map(({ type, label, checked, disabled, onChange }) => (
          <div className="d-flex flex-column align-items-center" key={type}>
            <MediumCheckboxWrapper disabled={disabled} medium={type}>
              <Checkbox
                id={label}
                color="#00B77A"
                checked={checked}
                disabled={disabled}
                onChange={(e, checked) => onChange(checked)}
              />
            </MediumCheckboxWrapper>
            <label htmlFor={label} className={styles.commLabel}>
              {label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OfferingCommCard;
