import classnames from "classnames";
import React from "react";
import styles from "./MasterControls.module.css";
import { CategoryWiseSettings } from "./modules/CategoryWiseSettings/CategoryWiseSettings";
import { MasterSettings } from "./modules/MasterSettings/MasterSettings";
import SectionLabel from "./modules/SectionLabel/SectionLabel";

export const MasterControls = () => {
  return (
    <>
      <div className={classnames("mb-2", styles.root)}>
        <SectionLabel
          className={styles.label}
          label="Master settings"
          info="Enable or disable Email, WhatsApp and SMS notifications across all categories"
        />

        <MasterSettings />
      </div>

      <div className={styles.root}>
        <SectionLabel
          className={styles.label}
          label="Category-wise settings"
          info="Enable or disable Email, WhatsApp and SMS notifications for each category"
        />

        <CategoryWiseSettings />
      </div>
    </>
  );
};
