import { Button } from "@material-ui/core";
import EyeIcon from "@material-ui/icons/VisibilityOutlined";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import classnames from "classnames";
import ExlyImage from "common/Components/ExlyImage";
import { AUTOMATED_SUPPORTED_COMMS_API_VALUES } from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";
import React, { useState } from "react";
import { useForm } from "react-final-form";
import { useToggleState } from "utils/hooks";
import {
  EDIT_COMM_FE_KEYS,
  EDIT_COMM_FORM_KEYS,
} from "../../constants/EditAutomatedCommunication.constants";
import styles from "./CommActions.module.css";
import ChangeWhatsAppTemplate from "./modules/ChangeWhatsAppTemplate/ChangeWhatsAppTemplate";
import PreviewEmailComm from "./modules/PreviewEmailComm/PreviewEmailComm";
import PreviewSMSComm from "./modules/PreviewSMSComm/PreviewSMSComm";
import SendTestEmailModal from "./modules/SendTestEmailModal/SendTestEmailModal";
import WhatsAppCommPreviewPopup from "./modules/WhatsAppCommPreviewPopup/WhatsAppCommPreviewPopup";
import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";

export const CommActions = () => {
  const isDesktop = useIsDesktop();
  const form = useForm();
  const [
    isSendTestEmailModalOpen,
    openSendTestEmailModalOpen,
    closeSendTestEmailModalOpen,
  ] = useToggleState(false);
  const [
    isChangeWhatsAppTemplateModalOpen,
    openChangeWhatsAppTemplateModal,
    closeChangeWhatsAppTemplateModal,
  ] = useToggleState(false);
  const [isWhatsAppPreviewOpen, openWhatsAppPreview, closeWhatsAppPreview] =
    useToggleState(false);
  const [isSMSPreviewOpen, openSMSPreview, closeSMSPreview] =
    useToggleState(false);
  const [emailPreviewState, setEmailPreviewState] = useState();
  const isEmailPreviewOpen = !!emailPreviewState;
  const commType = useFormFieldValue(EDIT_COMM_FORM_KEYS.comm_type);
  const isWhatsappIntegrated = useFormFieldValue(
    EDIT_COMM_FE_KEYS.fe_use_whitelabel_wa_number
  );

  const handleOpenEmailPreview = () => {
    const currentEmailBody = form.getFieldState(
      EDIT_COMM_FE_KEYS.fe_email_body
    ).value;
    setEmailPreviewState(currentEmailBody);
  };

  const handleCloseEmailPreview = () => {
    setEmailPreviewState();
  };

  const commonActions = [
    {
      label: "Preview",
      icon: EyeIcon,
      onClick: {
        [AUTOMATED_SUPPORTED_COMMS_API_VALUES.EMAIL]: handleOpenEmailPreview,
        [AUTOMATED_SUPPORTED_COMMS_API_VALUES.WHATSAPP]: openWhatsAppPreview,
        [AUTOMATED_SUPPORTED_COMMS_API_VALUES.SMS]: openSMSPreview,
      }[commType],
    },
  ];

  const emailActions = [
    ...commonActions,
    {
      label: "Send test email",
      icon: () => (
        <ExlyImage
          src={getS3AssetCDNUrl("assets/vectors/common/dashed_arrow.svg")}
          alt=""
        />
      ),
      onClick: openSendTestEmailModalOpen,
    },
  ];

  const whatsappActions = [
    ...commonActions,
    {
      label: "Change Template",
      icon: () => (
        <ExlyImage
          src={getS3AssetCDNUrl("assets/vectors/common/change_template.svg")}
          alt=""
        />
      ),
      onClick: openChangeWhatsAppTemplateModal,
      hide: !isWhatsappIntegrated,
    },
  ];

  const actions = {
    [AUTOMATED_SUPPORTED_COMMS_API_VALUES.EMAIL]: emailActions,
    [AUTOMATED_SUPPORTED_COMMS_API_VALUES.WHATSAPP]: whatsappActions,
    [AUTOMATED_SUPPORTED_COMMS_API_VALUES.SMS]: commonActions,
  };

  return (
    <>
      <div className={classnames("d-flex", styles.actions)}>
        {actions[commType].map(
          ({ hide, label, icon: Icon, onClick }) =>
            !hide && (
              <Button
                key={label}
                className={styles.action}
                startIcon={<Icon />}
                onClick={onClick}
                variant={!isDesktop && "outlined"}
                fullWidth={!isDesktop}
              >
                {label}
              </Button>
            )
        )}
      </div>

      {isSendTestEmailModalOpen && (
        <SendTestEmailModal
          open={isSendTestEmailModalOpen}
          onClose={closeSendTestEmailModalOpen}
        />
      )}

      <PreviewEmailComm
        open={isEmailPreviewOpen}
        state={emailPreviewState}
        onClose={handleCloseEmailPreview}
      />

      <WhatsAppCommPreviewPopup
        open={isWhatsAppPreviewOpen}
        onClose={closeWhatsAppPreview}
      />

      <PreviewSMSComm open={isSMSPreviewOpen} onClose={closeSMSPreview} />

      {isChangeWhatsAppTemplateModalOpen && (
        <ChangeWhatsAppTemplate
          open={isChangeWhatsAppTemplateModalOpen}
          onClose={closeChangeWhatsAppTemplateModal}
        />
      )}
    </>
  );
};
