import { Button } from "@my-scoot/component-library-legacy";
import { Header } from "common/Components/Header";
import { AUTOMATED_COMMS_URL_PARAMS } from "features/AutomatedCommunication/constants/AutomatedComms.urlParams";
import { isSMSCommType } from "features/AutomatedCommunication/utils/AutomatedCommunication.utils";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";
import { getRAUrlParams } from "features/Common/utils/url.utils";
import React from "react";
import { EDIT_COMM_FORM_KEYS } from "../../constants/EditAutomatedCommunication.constants";
import styles from "./EditCommPageHeader.module.css";

const EditCommPageHeader = ({ submitting, onSave }) => {
  const urlParams = getRAUrlParams();
  const commInfo = urlParams.get(AUTOMATED_COMMS_URL_PARAMS.comm_info);
  const commType = useFormFieldValue(EDIT_COMM_FORM_KEYS.comm_type);

  return (
    <Header
      title={
        <>
          <div className="mb-1">Edit Message</div>
          <div className={styles.commLabel}>{commInfo}</div>
        </>
      }
      right={
        isSMSCommType(commType) ? undefined : (
          <Button onClick={onSave} loading={submitting} disabled={submitting}>
            Save
          </Button>
        )
      }
      classNames={{ container: styles.headerContainer }}
    />
  );
};

export default EditCommPageHeader;
