import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { AUTOMATED_COMMS_APIS } from "features/AutomatedCommunication/constants/AutomatedComms.api";
import { isRequestSuccessful } from "utils/Utils";

export const saveAutomatedCommsMasterControls = async (payload) => {
  const { data, status } = await dataProvider.custom_request(
    AUTOMATED_COMMS_APIS.manage_master_settings,
    apiMethods.POST,
    payload
  );

  if (!isRequestSuccessful(status))
    throw new Error(
      `Error saving automated comms master controls settings: ${data.message}`
    );
};

export const saveAutomatedCommsSenderDetails = async (payload) => {
  const { data, status } = await dataProvider.custom_request(
    AUTOMATED_COMMS_APIS.manage_sender_management,
    apiMethods.POST,
    payload
  );

  if (!isRequestSuccessful(status))
    throw new Error(
      `Error saving automated comms sender details settings: ${data.message}`
    );
};
