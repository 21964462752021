import { IconButton } from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBackRounded";
import classnames from "classnames";
import { AUTOMATED_COMMS_TABLE_ROW_KEYS } from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { ExlyModal } from "features/Common/modules/ExlyModal/ExlyModal";
import React from "react";
import styles from "./EditCommOfferingsPopup.module.css";
import { EditOfferingCommBase } from "./modules/EditOfferingCommBase/EditOfferingCommBase";

const EditCommOfferingsPopup = (props) => {
  const { open, comm, onClose } = props;
  const { [AUTOMATED_COMMS_TABLE_ROW_KEYS.cdb_type]: cdbType } = comm;

  return (
    <ExlyModal
      title={
        <div className={classnames("d-flex align-items-center", styles.header)}>
          <IconButton className="p-0" onClick={onClose}>
            <BackIcon />
          </IconButton>
          {cdbType}
        </div>
      }
      open={open}
      onClose={onClose}
      className={styles.root}
      mobile_modal_props={{ hideCross: true, keepMounted: true }}
      modal_props={{ hideCross: true, keepMounted: true }}
      customFooter={<></>}
    >
      <EditOfferingCommBase {...props} />
    </ExlyModal>
  );
};

export default EditCommOfferingsPopup;
