import { MASTER_CONTROLS_FORM_KEYS } from "../../../constants/MasterControls.constants";

export const enableCommMediumFromComms = (commType, comms) => {
  return comms.map((comm) => {
    const {
      [MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums]: mediums,
    } = comm;

    return {
      ...comm,
      [MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums]: [
        ...mediums,
        commType,
      ],
    };
  });
};

export const disableCommMediumFromComms = (commType, comms) => {
  return comms.map((comm) => {
    const {
      [MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums]: mediums,
    } = comm;

    return {
      ...comm,
      [MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums]: mediums.filter(
        (medium) => medium !== commType
      ),
    };
  });
};
