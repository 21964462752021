import { appendUrlParams } from "utils/urlUtils";
import { AUTOMATED_COMMS_TARGETS } from "./AutomatedComms.targets";

export const AUTOMATED_COMMS_APIS = {
  fetch_comms_listings: "notifications/fetch/comms/listing/list",
  manage_master_settings: "notifications/mastersettings",
  manage_sender_management: "notifications/sendermanagement",
  send_test_email: "notifications/v2/test/communication",
  fetch_whatsapp_preset_notifications_templates:
    "notifications/whatsapp/templates/preset/default",

  fetch_automated_comms: (
    commsTarget = AUTOMATED_COMMS_TARGETS.SENT_TO_USERS
  ) => `/notifications/fetch/${commsTarget}/comms/list`,
  update_comm_type_enabled_state: (commTitle) =>
    `notifications/exly/${commTitle}/update`,
  manage_category_wise_settings: (commsTarget) =>
    `notifications/v2/fetch/${commsTarget}/comms/category`,
  fetch_comm_details: ({ commTitle, listingUuid }) =>
    appendUrlParams(`notifications/v2/exly/${commTitle}/details`, {
      listing_uuid: listingUuid,
    }),
  save_edited_comm: (commTitle) =>
    `notifications/exly/${commTitle}/content/update`,
};
