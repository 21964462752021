import { Switch } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import React from "react";
import styles from "./CommSwitch.module.css";

const CommSwitch = ({ className, label, value, enabled, onChange }) => {
  const handleChange = (e, enabled) => {
    onChange(value, enabled);
  };

  return (
    <div className={classnames(styles.root, className)}>
      {label}
      <Switch
        checked={enabled}
        size="large"
        color="success"
        onChange={handleChange}
      />
    </div>
  );
};

export default CommSwitch;
