import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import DownIcon from "@material-ui/icons/ExpandMoreRounded";
import classnames from "classnames";
import { useCustomInfiniteQuery } from "features/Common/modules/Api/hooks/useCustomInfiniteQuery";
import { AUTOMATED_COMMS_APIS } from "features/AutomatedCommunication/constants/AutomatedComms.api";
import { AUTOMATED_COMM_LISTING_KEYS } from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { useAutomatedCommsStore } from "features/AutomatedCommunication/store/useAutomatedCommsStore";
import { fetchAutomatedCommListings } from "features/AutomatedCommunication/utils/AutomatedCommunication.apiCalls";
import React, { useEffect, useState } from "react";
import styles from "./CommListings.module.css";
import { ListingCommRow } from "./modules/ListingCommRow/ListingCommRow";

const handleGetNextListingsPage = (lastPageData) => {
  const { page, totalPages } = lastPageData;
  return page < totalPages ? page : undefined;
};

export const CommListings = ({ commTitle, onReset, onEdit, enabled }) => {
  const [enableFetching, setEnableFetching] = useState(enabled);
  const { data, hasNextPage, isFetching, isFetchingNextPage, fetchNextPage } =
    useCustomInfiniteQuery({
      queryFn: fetchAutomatedCommListings({ commTitle }),
      queryKey: [
        AUTOMATED_COMMS_APIS.fetch_comms_listings,
        { comm_title: commTitle },
      ],
      getNextPageParam: handleGetNextListingsPage,
      enabled: enableFetching,
      cacheTime: 0,
    });
  const showShowMoreCta = hasNextPage && !isFetchingNextPage;
  const { pages = [] } = data ?? {};
  const [firstPageData = {}] = pages;
  const { total = 0 } = firstPageData;

  const initOfferingsEnabledCommsState = useAutomatedCommsStore(
    (state) => state.initOfferingsEnabledCommsState
  );

  useEffect(() => {
    // initialize current comm category offerings enabled comms mediums state
    // this enables parent comm to handle the state of their offerings
    const handleInitializeListingData = () => {
      if (!isFetching && !isFetchingNextPage && data) {
        const { pages } = data;
        const lastPageData = pages[pages.length - 1]; // initialize for new page only
        const { data: listingComms } = lastPageData;

        initOfferingsEnabledCommsState({
          commTitle,
          offeringsComms: listingComms,
        });
      }
    };

    handleInitializeListingData();
  }, [data, isFetching, isFetchingNextPage]);

  useEffect(() => {
    const handleEnableFetching = () => {
      // start offerings comms fetching if ever mounted
      if (enabled && !enableFetching) {
        setEnableFetching(true);
      }
    };

    handleEnableFetching();
  }, [enabled, enableFetching]);

  if (!enabled || !enableFetching) return null; // show offerings if expanded

  const handleReset = (inputComm) => () => {
    const { [AUTOMATED_COMM_LISTING_KEYS.listing_uuid]: listingUuid } =
      inputComm;
    onReset({ commTitle, listingUuid });
  };

  const handleEditListingComm = (inputComm) => () => {
    const { [AUTOMATED_COMM_LISTING_KEYS.listing_uuid]: listingUuid } =
      inputComm;
    onEdit({
      [AUTOMATED_COMM_LISTING_KEYS.listing_uuid]: listingUuid,
    });
  };

  if (!isFetching && !total) {
    return (
      <tr>
        <td colSpan={5}>No offerings added yet</td>
      </tr>
    );
  }

  return (
    <>
      <tr className={styles.offeringRow}>
        <td
          colSpan={5}
          className={classnames(
            styles.offeringTitleCol,
            styles.offerinNamePlaceholder
          )}
        >
          Offering Name
        </td>
      </tr>

      {data?.pages?.map(({ data: listingComms }) =>
        listingComms?.map((comm) => (
          <ListingCommRow
            key={
              comm[
                AUTOMATED_COMM_LISTING_KEYS.has_default_message_content
                  .listingUuid
              ]
            }
            comm={comm}
            commTitle={commTitle}
            className={styles.offeringRow}
            titleColClassName={styles.offeringTitleCol}
            onReset={handleReset(comm)}
            onEdit={handleEditListingComm(comm)}
          />
        ))
      )}

      {isFetching && (
        <tr>
          <td colSpan={5} align="center">
            <CircularProgress className={styles.loader} />
          </td>
        </tr>
      )}

      {showShowMoreCta && (
        <tr className={styles.offeringRow}>
          <td colSpan={5} className={styles.offeringTitleCol}>
            <Button
              endIcon={<DownIcon />}
              className={styles.loadMoreTitle}
              onClick={fetchNextPage}
            >
              Show more
            </Button>
          </td>
        </tr>
      )}
    </>
  );
};

export default CommListings;
