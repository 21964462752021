import {
  AUTOMATED_COMMS_TABLE_KEYS,
  AUTOMATED_COMMS_TABLE_ROW_KEYS,
} from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { useAutomatedCommsStore } from "features/AutomatedCommunication/store/useAutomatedCommsStore";
import { isTargetCommCreator } from "features/AutomatedCommunication/utils/AutomatedCommunication.utils";
import React from "react";
import { CommRow } from "../CommRow/CommRow";
import styles from "./DesktopCommsTable.module.css";
import { getHeaders } from "./utils/DesktopCommsTable.utils";

const DesktopCommsTable = ({ table, onEdit, onReset }) => {
  const {
    [AUTOMATED_COMMS_TABLE_KEYS.key]: categoryKey,
    [AUTOMATED_COMMS_TABLE_KEYS.comms]: comms,
  } = table;
  const activeTab = useAutomatedCommsStore((state) => state.activeTab);
  const columns = getHeaders({ showActions: !isTargetCommCreator(activeTab) });

  return (
    <div className={styles.root}>
      <table className={styles.tableRoot}>
        <thead className={styles.header}>
          <tr>
            {columns.map(({ title, align }) => (
              <th style={{ textAlign: align }} key={title}>
                {title}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {comms.map((comm) => (
            <CommRow
              key={comm[AUTOMATED_COMMS_TABLE_ROW_KEYS.title]}
              categoryKey={categoryKey}
              comm={comm}
              onReset={onReset}
              onEdit={onEdit}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DesktopCommsTable;
