import { Button } from "@material-ui/core";
import classnames from "classnames";
import { app_pathnames, app_route_keys } from "constants/urlPaths";
import { whatsapp } from "features/Integration/constants/Integration.constants";
import React from "react";
import { useHistory } from "react-router";
import styles from "./ConnectWhatsAppNumberStrip.module.css";

const ConnectWhatsAppNumberStrip = () => {
  const history = useHistory();

  const handleRedirectToWhatsappIntegration = () => {
    history.push(
      app_pathnames[app_route_keys.integration_details]({
        slug: whatsapp,
      })
    );
  };

  return (
    <div
      className={classnames(
        "d-flex align-items-center justify-content-between",
        styles.root
      )}
    >
      💡 To customize this message, please connect your personal WhatsApp number
      <Button onClick={handleRedirectToWhatsappIntegration}>
        Connect Your Number
      </Button>
    </div>
  );
};

export default ConnectWhatsAppNumberStrip;
