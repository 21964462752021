import {
  AUTOMATED_COMMS_COLUMNS,
  AUTOMATED_COMMS_HEADERS_MAP,
} from "../constants/DesktopCommsTable.constants";

export const getHeaders = ({ showActions }) => {
  const headers = [
    AUTOMATED_COMMS_HEADERS_MAP[AUTOMATED_COMMS_COLUMNS.COMM_TITLE],
    AUTOMATED_COMMS_HEADERS_MAP[AUTOMATED_COMMS_COLUMNS.EMAIL],
    AUTOMATED_COMMS_HEADERS_MAP[AUTOMATED_COMMS_COLUMNS.WHATSAPP],
    AUTOMATED_COMMS_HEADERS_MAP[AUTOMATED_COMMS_COLUMNS.SMS],
  ];

  if (showActions)
    headers.push(AUTOMATED_COMMS_HEADERS_MAP[AUTOMATED_COMMS_COLUMNS.ACTIONS]);

  return headers;
};
