import { useCustomQuery } from "data/modules/api/hooks/useCustomQuery";
import { EDIT_COMM_FE_KEYS } from "features/AutomatedCommunication/modules/EditAutomatedCommunication/constants/EditAutomatedCommunication.constants";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";
import React from "react";
import { whatsapp_broadcasts_apis } from "ui/pages/whatsappBroadcast/whatsappBroadCast.api";
import { renderWhatsappFormatWithVariables } from "ui/pages/whatsappBroadcast/whatsappBroadcast.constants";
import WhatsAppMobilePlaceholder from "../ChangeWhatsAppTemplate/modules/WhatsAppTemplatePreview/modules/WhatsAppMobilePlaceholder/WhatsAppMobilePlaceholder";
import PreviewComm from "../PreviewComm/PreviewComm";
import styles from "./WhatsAppCommPreviewPopup.module.css";

const WhatsAppCommPreviewPopup = (props) => {
  const { open } = props;
  const templateUuid = useFormFieldValue(
    EDIT_COMM_FE_KEYS.fe_whatsapp_template_uuid
  );
  const { data: { template: { body } = {} } = {} } = useCustomQuery({
    url: whatsapp_broadcasts_apis.get_templates,
    queryKey: [
      whatsapp_broadcasts_apis.get_templates,
      { template_uuid: templateUuid },
    ],
    params: { template_uuid: templateUuid },
    enabled: open,
  });

  return (
    <PreviewComm
      title="WhatsApp Preview"
      subTitle="This is how your message will look like"
      {...props}
    >
      <div className="m-4 d-flex justify-content-center align-items-center">
        <WhatsAppMobilePlaceholder classes={{ root: styles.root }}>
          <div
            className={styles.body}
            dangerouslySetInnerHTML={{
              __html: renderWhatsappFormatWithVariables({})(body),
            }}
          />
        </WhatsAppMobilePlaceholder>
      </div>
    </PreviewComm>
  );
};

export default WhatsAppCommPreviewPopup;
