import { Tooltip } from "@my-scoot/component-library-legacy";
import { SUPPORTED_COMMS_LABELS } from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import React, { Fragment } from "react";
import styles from "./MediumCheckboxWrapper.module.css";

const TooltipWrapper = ({ children, medium }) => {
  return (
    <Tooltip
      color="primary"
      arrow
      title={`${SUPPORTED_COMMS_LABELS[medium]} is not supported for this communication`}
    >
      <div className={styles.root}>{children}</div>
    </Tooltip>
  );
};

export const MediumCheckboxWrapper = ({ disabled, medium, children }) => {
  const Wrapper = disabled ? TooltipWrapper : Fragment;
  const wrapperProps = disabled ? { medium } : {};
  return <Wrapper {...wrapperProps}>{children}</Wrapper>;
};
