import { HelpOutlineRounded } from "@material-ui/icons";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import { Button, Tabs } from "@my-scoot/component-library-legacy";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import classnames from "classnames";
import { Header } from "common/Components/Header";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import withComponentLibraryTheme from "hocs/withComponentLibraryTheme";
import useAppLayoutControl from "hooks/useAppLayoutControl";
import React from "react";
import { AUTOMATED_MESSAGES_LEARN_MORE_HREFS } from "ui/pages/communications/Customer/Constants/constant";
import { useNotifications, useToggleState } from "utils/hooks";
import styles from "./AutomatedCommunication.module.css";
import {
  AUTOMATED_COMMS_FEATURE_NAME,
  AUTOMATED_COMMS_TABS,
} from "./constants/AutomatedComms.constants";
import { AUTOMATED_COMMS_TARGETS } from "./constants/AutomatedComms.targets";
import { AUTOMATED_COMMS_URL_PARAMS } from "./constants/AutomatedComms.urlParams";
import { CommsSettingsModal } from "./modules/CommsSettingsModal/CommsSettingsModal";
import { CommsTables } from "./modules/CommsTables/CommsTables";
import { useAutomatedCommsStore } from "./store/useAutomatedCommsStore";
import {
  isTargetCommCreator,
  isTargetCommUsers,
} from "./utils/AutomatedCommunication.utils";
import { useAutomatedCommsTabs } from "./utils/useAutomatedCommsTabs";

const AutomatedCommunication = () => {
  const isDesktop = useIsDesktop();
  const { notify } = useNotifications();
  const { activeTab, handleTabChange } = useAutomatedCommsTabs({
    defaultValue: AUTOMATED_COMMS_TARGETS.SENT_TO_USERS,
    key: AUTOMATED_COMMS_URL_PARAMS.tab,
  });
  const [isSettingsOpen, openSettings, closeSettings] = useToggleState(false);
  const isActiveTabUsers = isTargetCommUsers(activeTab);
  const isActiveTabCreator = isTargetCommCreator(activeTab);
  const setActiveTab = useAutomatedCommsStore((state) => state.setActiveTab);

  useAppLayoutControl({
    values: {
      title: (
        <div className="d-flex align-items-center justify-content-between">
          {AUTOMATED_COMMS_FEATURE_NAME}
          <div className="d-flex align-items-center">
            <LearnMoreCta
              className={classnames(styles.navAction, "mr-3")}
              size="thin"
              color="tertiary"
              variant="outlined"
              startIcon={undefined}
              href={AUTOMATED_MESSAGES_LEARN_MORE_HREFS.AUTOMATED_MESSAGES}
            >
              <HelpOutlineRounded />
            </LearnMoreCta>
            <Button
              className={styles.navAction}
              size="thin"
              onClick={openSettings}
            >
              <SettingsOutlinedIcon />
            </Button>
          </div>
        </div>
      ),
      showBottomNav: false,
    },
    control: {
      appBar: !isDesktop,
      bottomNav: !isDesktop,
      title: !isDesktop,
    },
  });

  const handleActiveTabChange = (e, tab) => {
    handleTabChange(e, tab);
    setActiveTab(tab);
  };

  return (
    <>
      <div className={styles.root}>
        {isDesktop && (
          <Header
            title={AUTOMATED_COMMS_FEATURE_NAME}
            right={
              <div className="d-flex">
                <LearnMoreCta
                  href={AUTOMATED_MESSAGES_LEARN_MORE_HREFS.AUTOMATED_MESSAGES}
                />

                <Button
                  onClick={openSettings}
                  startIcon={<SettingsOutlinedIcon />}
                  className="ml-3"
                >
                  Settings
                </Button>
              </div>
            }
            classNames={{ container: styles.headerContainer }}
            hideBackButton
          />
        )}

        <div className={styles.body}>
          <Tabs
            value={activeTab}
            tabs={AUTOMATED_COMMS_TABS}
            tabVariant="filled_primary"
            classes={{ scroller: "border-none" }}
            tab_classes={{
              root: styles.tabRoot,
              selected: styles.tabSelected,
            }}
            onChange={handleActiveTabChange}
          />

          <div
            className={classnames(
              styles.tables,
              isActiveTabUsers ? undefined : "d-none"
            )}
          >
            <CommsTables
              active={isActiveTabUsers}
              commsTarget={AUTOMATED_COMMS_TARGETS.SENT_TO_USERS}
            />
          </div>

          <div
            className={classnames(
              styles.tables,
              isActiveTabCreator ? undefined : "d-none"
            )}
          >
            <CommsTables
              active={isActiveTabCreator}
              commsTarget={AUTOMATED_COMMS_TARGETS.SENT_TO_CREATOR}
            />
          </div>
        </div>
      </div>

      {isSettingsOpen && (
        <CommsSettingsModal
          open={isSettingsOpen}
          onClose={closeSettings}
          notify={notify}
        />
      )}
    </>
  );
};

export default withComponentLibraryTheme(AutomatedCommunication);
