import { Checkbox } from "@my-scoot/component-library-legacy";
import { SUPPORTED_COMMS_OPTIONS } from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { MASTER_CONTROLS_FORM_KEYS } from "features/AutomatedCommunication/modules/CommsSettingsModal/modules/MasterControls/constants/MasterControls.constants";
import { hasCommType } from "features/AutomatedCommunication/modules/CommsTable/modules/CommRow/utils/CommRow.utils";
import React from "react";
import { Field } from "react-final-form";
import { isCategoryCommsEqual } from "../../../../utils/CategoryWiseSettings.utils";
import styles from "./MobileCategoryComm.module.css";

const MobileCategoryComm = ({ name: fieldName, comm, onChange }) => {
  const {
    [MASTER_CONTROLS_FORM_KEYS.name]: name,
    [MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums]: enabledComms,
  } = comm;

  const handleChange = (commType) => (e, activate) => {
    onChange(commType, activate);
  };

  return (
    <div className={styles.root}>
      <Field
        name={fieldName}
        component={() => null}
        isEqual={isCategoryCommsEqual}
      />

      <div className={styles.header}>{name}</div>

      <div className={styles.body}>
        {SUPPORTED_COMMS_OPTIONS.map(({ label, value }) => (
          <div className="d-flex flex-column align-items-center" key={value}>
            <Checkbox
              id={label}
              checked={hasCommType(enabledComms, value)}
              color="#00B77A"
              onChange={handleChange(value)}
            />
            <label htmlFor={label} className={styles.commLabel}>
              {label}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileCategoryComm;
