import CircularProgress from "@material-ui/core/CircularProgress";
import ExlyFilteredEmptyState from "common/Components/ExlyTable/ExlyTableFilters/ExlyTableFilterEmptyState";
import { AUTOMATED_COMMS_WA_PRESET_TEMPLATE_KEYS } from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { useInfiniteScroll } from "features/Common/hooks/useInfiniteScroll";
import { useCustomInfiniteQuery } from "features/Common/modules/Api/hooks/useCustomInfiniteQuery";
import ExlyEmptyState from "features/Common/modules/ExlyEmptyState/ExlyEmptyState";
import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";
import { is_empty } from "features/Common/utils/common.utils";
import React from "react";
import WhatsAppTemplate from "../WhatsAppTemplate/WhatsAppTemplate";
import styles from "./WhatsappTemplatesList.module.css";

const handleGetNextListingsPage = (lastPageData) => {
  const { page, totalPages } = lastPageData;
  return page < totalPages ? page : undefined;
};

export const WhatsappTemplatesList = ({
  queryFn,
  api,
  formatTemplateData,
  open,
  filters,
  emptyStateProps,
  onChoose,
  onPreview,
}) => {
  const { data, isFetching, fetchNextPage } = useCustomInfiniteQuery({
    cacheTime: 0,
    queryFn: queryFn(filters),
    queryKey: [api, filters],
    getNextPageParam: handleGetNextListingsPage,
    enabled: open,
    select: (data) => {
      const templates = data.pages.flatMap((page) =>
        page.data.map(formatTemplateData ?? ((template) => template))
      );
      return { ...data, templates };
    },
  });
  const { lastElementRef } = useInfiniteScroll({
    onLoadMore: fetchNextPage,
    loading: isFetching,
  });
  const [firstPageData = {}] = data?.pages ?? [];
  const { total } = firstPageData;
  const showFiltersEmptyState = !isFetching && !is_empty(filters) && !total;
  const showEmptyState = !isFetching && is_empty(filters) && !total;

  const handleChooose = (template) => () => {
    onChoose(template);
  };

  const handlePreview = (template) => () => {
    onPreview(template);
  };

  return (
    <>
      <div className={styles.templates}>
        {data?.templates?.map((template) => (
          <WhatsAppTemplate
            key={template[AUTOMATED_COMMS_WA_PRESET_TEMPLATE_KEYS.uuid]}
            body={
              template[AUTOMATED_COMMS_WA_PRESET_TEMPLATE_KEYS.whatsapp_body]
            }
            title={template[AUTOMATED_COMMS_WA_PRESET_TEMPLATE_KEYS.cdb_type]}
            type={
              template[AUTOMATED_COMMS_WA_PRESET_TEMPLATE_KEYS.category_name]
            }
            onChoose={handleChooose(template)}
            onPreview={handlePreview(template)}
          />
        ))}

        <div ref={lastElementRef} />
      </div>

      {isFetching && (
        <div className="text-center">
          <CircularProgress />
        </div>
      )}

      {showFiltersEmptyState && <ExlyFilteredEmptyState />}

      {showEmptyState && (
        <ExlyEmptyState
          className="text-center align-items-center"
          classes={{ actions: "justify-content-center" }}
          title="No templates yet!"
          description="You don't have any notification templates yet. Once you've created one, it'll appear here"
          imgSrc={getS3AssetCDNUrl(
            "assets/vectors/whatsapp_broadcast/no_templates.svg"
          )}
          {...emptyStateProps}
        />
      )}
    </>
  );
};
