export const COMMS_SETTINGS_MASTER_CONTROLS_TAB = {
  label: "Master Controls",
  value: 1,
};

export const COMMS_SETTINGS_SENDER_MANAGEMENT_TAB = {
  label: "Sender Management",
  value: 2,
};

export const COMMS_SETTINGS_TABS = [
  COMMS_SETTINGS_MASTER_CONTROLS_TAB,
  COMMS_SETTINGS_SENDER_MANAGEMENT_TAB,
];
