import React from "react";
import { CommActions } from "../CommActions/CommActions";
import { CommTypeTabs } from "../CommTypeTabs/CommTypeTabs";
import styles from "./CommToolbar.module.css";

const CommToolbar = () => {
  return (
    <div className={styles.root}>
      <CommTypeTabs />
      <CommActions />
    </div>
  );
};

export default CommToolbar;
