import { IconButton } from "@material-ui/core";
import { Tooltip } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import React, { Fragment } from "react";
import styles from "./CommAction.module.css";

export const CommAction = ({ icon: Icon, onClick, tooltip }) => {
  const Wrapper = tooltip ? Tooltip : Fragment;
  const wrapperProps = tooltip
    ? { title: "Reset communication", color: "primary", arrow: true }
    : {};

  return (
    <Wrapper {...wrapperProps}>
      <IconButton
        className={classnames("p-0", styles.actionCta)}
        onClick={onClick}
      >
        <Icon />
      </IconButton>
    </Wrapper>
  );
};
