import { useHistory, useLocation } from "react-router-dom";

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const useAutomatedCommsTabs = ({ defaultValue, key }) => {
  const location = useLocation();
  const query = useQuery();
  const history = useHistory();
  const activeTab = query.get(key) || defaultValue;

  const handleTabChange = (e, tab) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(key, tab);
    history.replace({ search: searchParams.toString() });
  };

  return { activeTab, handleTabChange };
};
