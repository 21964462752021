import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { Button } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import React from "react";
import styles from "./InfoAction.module.css";

const InfoAction = ({
  info,
  actionText,
  onActionClick,
  classes = {},
  actionProps,
}) => {
  return (
    <div
      className={classnames(
        "d-flex align-items-center justify-content-between",
        styles.root,
        classes.root
      )}
    >
      <div className={styles.info}>
        <InfoIcon /> {info}
      </div>

      {onActionClick && (
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<EditOutlinedIcon />}
          className="text-nowrap"
          onClick={onActionClick}
          {...actionProps}
        >
          {actionText}
        </Button>
      )}
    </div>
  );
};

export default InfoAction;
