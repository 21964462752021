import { RichTextEditorUtils } from "@my-scoot/component-library-legacy";
import { COMMS_TYPES_COLUMN_ORDER } from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { getRAUrlParams } from "features/Common/utils/url.utils";
import {
  EDIT_COMM_FE_KEYS,
  EDIT_COMM_FORM_KEYS,
  EDIT_COMM_URL_PARAMS,
} from "../constants/EditAutomatedCommunication.constants";

// fixes old html handwritten templates for RTE
const fixHtmlBodyForRTE = (html) => {
  if (!html.includes("<br>")) return html; // Not an old template

  // Remove all $$ placeholders
  html = html.replace(/\$\$/g, "").trim();

  // Convert <div> to multiple <p> blocks, keeping inner content
  html = html.replace(/<div[^>]*>([\s\S]*?)<\/div>/g, (match, content) => {
    return content
      .split("<br>")
      .map((line) => `<p>${line.trim() || "&nbsp;"}</p>`)
      .join("");
  });

  // Ensure every <br> becomes a new <p> block
  let normalizedHtml = html
    .split("<br>")
    .map((content) => {
      // Prevent wrapping inline elements like <b>, <span>, <a> inside new <p>
      if (/^<(b|span|i|a|strong|em)[^>]*>/.test(content)) {
        return `<p>${content}</p>`;
      }
      return `<p>${content}</p>`;
    })
    .join("");

  // Fix cases where <p> is wrapping inline elements inside <a>
  normalizedHtml = normalizedHtml.replace(
    /(<a[^>]*>)(<p>)([\s\S]*?)(<\/p>)(<\/a>)/g,
    "$1$3$5"
  );

  return normalizedHtml;
};

const getInitialSelectedCommMedium = (allowedCommMediums) => {
  const urlParams = getRAUrlParams();
  const commTitle = urlParams.get(EDIT_COMM_URL_PARAMS.ct);

  // if selected comms medium type is present in query params already (sync tabs with url)
  if (commTitle) return commTitle;

  const allowedCommsSet = new Set(allowedCommMediums);
  const initialCommMedium = COMMS_TYPES_COLUMN_ORDER.find((type) =>
    allowedCommsSet.has(type)
  );

  // return first allowed comm medium type
  return initialCommMedium;
};

export const parseFormState = (payload) => {
  const {
    [EDIT_COMM_FORM_KEYS.email]: { [EDIT_COMM_FORM_KEYS.body]: emailBody },
    [EDIT_COMM_FORM_KEYS.allowed_communication_mediums]: allowedCommMediums,
  } = payload;

  return {
    [EDIT_COMM_FORM_KEYS.comm_type]:
      getInitialSelectedCommMedium(allowedCommMediums),
    [EDIT_COMM_FE_KEYS.fe_email_body]:
      RichTextEditorUtils.getEditorStateFromHtml(fixHtmlBodyForRTE(emailBody)),
    ...payload,
  };
};
