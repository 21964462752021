import { SUPPORTED_COMMS_OPTIONS } from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { isCommCategoryEqual } from "features/AutomatedCommunication/modules/CommsSettingsModal/utils/CommsSettingsModal.utils";
import React from "react";
import { FieldArray } from "react-final-form-arrays";
import { useManageMasterSettings } from "../../utils/useManageMasterSettings";
import styles from "./CategoryCommsTable.module.css";
import { SettingsCommRow } from "./modules/SettingsCommRow/SettingsCommRow";

const CategoryCommsTable = ({ commTarget, name }) => {
  const { generateCommActiveStateChangeHandler } = useManageMasterSettings({
    commTarget,
    name,
  });

  return (
    <table className={styles.root}>
      <thead className={styles.thead}>
        <tr>
          <th>Category title</th>

          {SUPPORTED_COMMS_OPTIONS.map(({ label, value }) => (
            <th key={value} align="center">
              <div className="text-center">{label}</div>
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        <FieldArray name={name} isEqual={isCommCategoryEqual}>
          {({ fields }) =>
            fields.map((name, index) => (
              <SettingsCommRow
                key={name}
                name={name}
                comm={fields.value[index]}
                onChange={generateCommActiveStateChangeHandler(index)}
              />
            ))
          }
        </FieldArray>
      </tbody>
    </table>
  );
};

export default CategoryCommsTable;
