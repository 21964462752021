import { useQueryClient } from "@tanstack/react-query";
import { AUTOMATED_COMMS_APIS } from "features/AutomatedCommunication/constants/AutomatedComms.api";
import { AUTOMATED_COMMS_TARGETS } from "features/AutomatedCommunication/constants/AutomatedComms.targets";
import arrayMutators from "final-form-arrays";
import React, { memo } from "react";
import { Form } from "react-final-form";
import { logError } from "utils/error";
import { notification_color_keys } from "utils/hooks";
import { CommsSettingsModalBody } from "./modules/CommsSettingsModalBody/CommsSettingsModalBody";
import { MASTER_CONTROLS_FORM_KEYS } from "./modules/MasterControls/constants/MasterControls.constants";
import {
  getDirtySettings,
  saveAutomatedCommsSettings,
} from "./utils/CommsSettingsModal.utils";
import { HTTP_STATUS_SUCCESS_CODES } from "data/api.constants";

const CommsSettingsModalComponent = (props) => {
  const { onClose, notify } = props;
  const queryClient = useQueryClient();

  const handleSaveSettings = (formData, form) => {
    const handleRefetchData = () => {
      Object.values(AUTOMATED_COMMS_TARGETS)
        .map((target) => AUTOMATED_COMMS_APIS.fetch_automated_comms(target))
        .forEach((key) => queryClient.refetchQueries([key]));
    };

    const handleSuccess = (status) => {
      if (status === HTTP_STATUS_SUCCESS_CODES.HTTP_STATUS_NOT_MODIFIED) {
        notify("Settings remain unchanged", notification_color_keys.info);
      } else {
        notify("Settings saved successfully", notification_color_keys.success);
        handleRefetchData();
      }
    };

    const handleError = (error) => {
      notify(
        error?.message ||
          "Something went wrong while saving automated messages settings, please try again",
        notification_color_keys.error
      );
      logError({
        error,
        when: "saving automated comms",
        occuredAt:
          "src/features/AutomatedCommunication/modules/CommsSettingsModal/CommsSettingsModal.jsx",
        severity: "blocker",
      });
    };

    // intentionally not using useCustomMutation hook to prevent react-final-form state getting reset on re-render
    return saveAutomatedCommsSettings(getDirtySettings(formData, form))
      .then(handleSuccess)
      .catch(handleError)
      .finally(onClose);
  };

  return (
    <Form
      onSubmit={handleSaveSettings}
      initialValues={{
        [MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums]: [],
        [MASTER_CONTROLS_FORM_KEYS.sent_to]:
          AUTOMATED_COMMS_TARGETS.SENT_TO_USERS,
      }}
      mutators={{
        ...arrayMutators,
      }}
    >
      {(formProps) => (
        <form onSubmit={formProps.handleSubmit}>
          <CommsSettingsModalBody {...formProps} {...props} />
        </form>
      )}
    </Form>
  );
};

// memoizing to prevent it from re-rendering after showing toast notification
// prevents react-final-form state getting reset on re-render
export const CommsSettingsModal = memo(
  CommsSettingsModalComponent,
  (prev, next) => prev.open === next.open
);
