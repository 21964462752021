import { useCustomMutation } from "data/modules/api/hooks/useCustomMutation";
import { AUTOMATED_COMMS_APIS } from "features/AutomatedCommunication/constants/AutomatedComms.api";
import {
  AUTOMATED_COMM_LISTING_KEYS,
  AUTOMATED_COMM_LISTING_UPDATE_PAYLOAD_KEYS,
  AUTOMATED_COMMS_TABLE_FE_KEYS,
} from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { useAutomatedCommsStore } from "features/AutomatedCommunication/store/useAutomatedCommsStore";
import { useOfferingComm } from "features/AutomatedCommunication/store/useAutomatedCommsStore.utils";
import {
  canEditComm,
  isTargetCommCreator,
} from "features/AutomatedCommunication/utils/AutomatedCommunication.utils";
import React from "react";
import { logError } from "utils/error";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { getCommColumns } from "../../../../utils/CommRow.utils";
import { CommMainRow } from "../../../CommMainRow/CommMainRow";

export const ListingCommRow = ({
  commTitle,
  comm,
  className,
  titleColClassName,
  onEdit,
  onReset,
}) => {
  const { notify } = useNotifications();
  const {
    [AUTOMATED_COMM_LISTING_KEYS.title]: title,
    [AUTOMATED_COMM_LISTING_KEYS.allowed_communication_mediums]:
      allowedCommsMediums,
    [AUTOMATED_COMM_LISTING_KEYS.listing_uuid]: listingUuid,
  } = comm;
  const { mutate: toggleCommTypeState } = useCustomMutation({
    url: AUTOMATED_COMMS_APIS.update_comm_type_enabled_state(commTitle),
    onError: () => {},
  });
  const setOfferingEnabledComms = useAutomatedCommsStore(
    (state) => state.setOfferingEnabledComms
  );
  const offeringCommData =
    useOfferingComm({
      commTitle,
      offeringUuid: listingUuid,
    }) ?? {};
  const enabledComms =
    offeringCommData[AUTOMATED_COMMS_TABLE_FE_KEYS.fe_enabled_mediums] ?? [];
  const hasDefaultSettings =
    !!offeringCommData[AUTOMATED_COMM_LISTING_KEYS.has_default_message_content];
  const activeTab = useAutomatedCommsStore((state) => state.activeTab);

  const generateCommTypeActiveStateToggleHandler =
    (inputCommType) => (activate) => {
      const prevEnabledComms = [...enabledComms];

      const handleRevert = () => {
        setOfferingEnabledComms({
          commTitle,
          offeringUuid: listingUuid,
          enabledComms: prevEnabledComms,
          targetMedium: inputCommType,
          activate: !activate,
        });
      };

      const handleError = (error) => {
        handleRevert();
        logError({
          error,
          when: "updating automated comms offering comm",
          severity: "blocker",
          occuredAt:
            "src/features/AutomatedCommunication/modules/CommsTable/modules/CommRow/modules/CommListings/modules/ListingCommRow/ListingCommRow.jsx",
        });
        notify(
          "Something went wrong, please try again",
          notification_color_keys.error
        );
      };

      let updatedEnabledComms = [...enabledComms];

      if (activate) {
        // enable comm type
        updatedEnabledComms.push(inputCommType);
      } else {
        // disable comm type
        updatedEnabledComms = updatedEnabledComms.filter(
          (commType) => commType !== inputCommType
        );
      }

      setOfferingEnabledComms({
        commTitle,
        offeringUuid: listingUuid,
        enabledComms: updatedEnabledComms,
        targetMedium: inputCommType,
        activate,
      });

      toggleCommTypeState(
        {
          listings: [
            {
              [AUTOMATED_COMM_LISTING_UPDATE_PAYLOAD_KEYS.uuid]: listingUuid,
              [AUTOMATED_COMM_LISTING_UPDATE_PAYLOAD_KEYS.enabled_communication_mediums]:
                updatedEnabledComms,
            },
          ],
        },
        { onError: handleError }
      );
    };

  const columns = getCommColumns({
    allowedCommsMediums,
    enabledCommsMediums: enabledComms,
    onChangeGetter: generateCommTypeActiveStateToggleHandler,
  });

  return (
    <CommMainRow
      title={title}
      className={className}
      titleColClassName={titleColClassName}
      commsColumns={columns}
      onReset={onReset}
      onEdit={onEdit}
      showReset={!hasDefaultSettings}
      showEdit={
        canEditComm(allowedCommsMediums) && !isTargetCommCreator(activeTab)
      }
    />
  );
};
