import {
  AUTOMATED_COMMS_TABLE_KEYS,
  AUTOMATED_COMMS_TABLE_ROW_KEYS,
} from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import React from "react";
import MobileCommRow from "../MobileCommItem/MobileCommRow";
import styles from "./MobileCommsTable.module.css";

const MobileCommsTable = ({ table, onEdit, onReset }) => {
  const {
    [AUTOMATED_COMMS_TABLE_KEYS.key]: categoryKey,
    [AUTOMATED_COMMS_TABLE_KEYS.comms]: comms,
  } = table;

  return (
    <div className={styles.root}>
      {comms.map((comm) => (
        <MobileCommRow
          key={comm[AUTOMATED_COMMS_TABLE_ROW_KEYS.title]}
          comm={comm}
          categoryKey={categoryKey}
          onEdit={onEdit}
          onReset={onReset}
        />
      ))}
    </div>
  );
};

export default MobileCommsTable;
