import ExpandIcon from "@material-ui/icons/ArrowForwardIosRounded";
import EditIcon from "@material-ui/icons/EditOutlined";
import ResetIcon from "@material-ui/icons/HistoryOutlined";
import { Checkbox } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import React from "react";
import { MediumCheckboxWrapper } from "../../../MediumCheckboxWrapper/MediumCheckboxWrapper";
import { CommAction } from "../CommAction/CommAction";
import styles from "./CommMainRow.module.css";

export const CommMainRow = ({
  title,
  info,
  commsColumns,
  expanded,
  titleColClassName,
  className,
  onExpand,
  onReset,
  onEdit,
  showReset,
  showEdit,
}) => {
  const actions = [
    {
      id: 1,
      icon: ResetIcon,
      tooltip: "Reset communication",
      onClick: onReset,
      show: showReset,
    },
    {
      id: 2,
      icon: EditIcon,
      onClick: onEdit,
      show: showEdit,
    },
  ];
  const showActionsColumn = actions.some((action) => action.show);

  return (
    <tr className={classnames(styles.commRow, className)}>
      <td
        width="50%"
        role="button"
        className={titleColClassName}
        onClick={onExpand}
      >
        <div
          className={classnames("d-flex align-items-center", styles.titleCol)}
        >
          {onExpand ? (
            <ExpandIcon
              className={classnames(
                styles.expandCta,
                expanded && styles.rotate
              )}
            />
          ) : null}

          <div>
            <div>{title}</div>
            {info ? <div className={styles.commInfo}>{info}</div> : null}
          </div>
        </div>
      </td>

      {commsColumns.map(
        ({ type, checked, disabled, indeterminate, onChange }) => (
          <td align="center" key={type}>
            <MediumCheckboxWrapper disabled={disabled} medium={type}>
              <Checkbox
                wrapperClassName="bg-white"
                disabled={disabled}
                checked={checked}
                color="#00B77A"
                indeterminate={indeterminate}
                onChange={(e, checked) =>
                  onChange(indeterminate ? true : checked)
                }
              />
            </MediumCheckboxWrapper>
          </td>
        )
      )}

      {showActionsColumn && (
        <td align="right">
          <div
            className={classnames("d-flex justify-content-end", styles.actions)}
          >
            {actions.map(
              (action) =>
                action.show && <CommAction key={action.id} {...action} />
            )}
          </div>
        </td>
      )}
    </tr>
  );
};
