import { useCustomInfiniteQuery } from "features/Common/modules/Api/hooks/useCustomInfiniteQuery";
import { AUTOMATED_COMMS_APIS } from "features/AutomatedCommunication/constants/AutomatedComms.api";
import { AUTOMATED_COMMS_TABLE_ROW_KEYS } from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { useAutomatedCommsStore } from "features/AutomatedCommunication/store/useAutomatedCommsStore";
import { fetchAutomatedCommListings } from "features/AutomatedCommunication/utils/AutomatedCommunication.apiCalls";
import React, { useEffect, useState } from "react";
import { EditOfferingsCommsContent } from "./modules/EditOfferingsCommsContent/EditOfferingsCommsContent";

const handleGetNextListingsPage = (lastPageData) => {
  const { page, totalPages } = lastPageData;
  return page < totalPages ? page : undefined;
};

export const EditOfferingCommBase = ({ open, comm, onReset, onEdit }) => {
  const { [AUTOMATED_COMMS_TABLE_ROW_KEYS.title]: commTitle } = comm;

  const [fetchingEnabled, setFetchingEnabled] = useState(open);
  const { data, isFetching, isFetchingNextPage, fetchNextPage } =
    useCustomInfiniteQuery({
      queryFn: fetchAutomatedCommListings({ commTitle }),
      queryKey: [
        AUTOMATED_COMMS_APIS.fetch_comms_listings,
        { comm_title: commTitle },
      ],
      enabled: fetchingEnabled,
      getNextPageParam: handleGetNextListingsPage,
      cacheTime: 0,
    });

  const initOfferingsEnabledCommsState = useAutomatedCommsStore(
    (state) => state.initOfferingsEnabledCommsState
  );

  useEffect(() => {
    // initialize current comm category offerings enabled comms mediums state
    // this enables parent comm to handle the state of their offerings
    const handleInitializeListingData = () => {
      if (!isFetching && !isFetchingNextPage && data) {
        const { pages } = data;
        const lastPageData = pages[pages.length - 1]; // initialize for new page only
        const { data: listingComms } = lastPageData;

        initOfferingsEnabledCommsState({
          commTitle,
          offeringsComms: listingComms,
        });
      }
    };

    handleInitializeListingData();
  }, [data, isFetching, isFetchingNextPage]);

  useEffect(() => {
    const handleEnableFetching = () => {
      // start offerings comms fetching if ever mounted
      if (open && !fetchingEnabled) {
        setFetchingEnabled(true);
      }
    };

    handleEnableFetching();
  }, [open, fetchingEnabled]);

  if (!open) return null;

  return (
    <EditOfferingsCommsContent
      comm={comm}
      data={data}
      fetchNextPage={fetchNextPage}
      fetching={isFetching || isFetchingNextPage}
      onEdit={onEdit}
      onReset={onReset}
    />
  );
};
