import CircularProgress from "@material-ui/core/CircularProgress";
import {
  AUTOMATED_COMM_LISTING_KEYS,
  AUTOMATED_COMMS_TABLE_ROW_KEYS,
} from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { useInfiniteScroll } from "features/Common/hooks/useInfiniteScroll";
import React from "react";
import OfferingCommCard from "../../../OfferingCommCard/OfferingCommCard";

export const EditOfferingsCommsContent = ({
  data,
  comm,
  fetching,
  fetchNextPage,
  onReset,
  onEdit,
}) => {
  const { [AUTOMATED_COMMS_TABLE_ROW_KEYS.title]: commTitle } = comm;
  const { lastElementRef } = useInfiniteScroll({
    onLoadMore: fetchNextPage,
    loading: fetching,
  });

  const handleReset = (inputComm) => {
    const { [AUTOMATED_COMM_LISTING_KEYS.listing_uuid]: listingUuid } =
      inputComm;
    onReset({ commTitle, listingUuid });
  };

  const handleEditListingComm = (inputComm) => () => {
    const { [AUTOMATED_COMM_LISTING_KEYS.listing_uuid]: listingUuid } =
      inputComm;
    onEdit({
      [AUTOMATED_COMM_LISTING_KEYS.listing_uuid]: listingUuid,
    });
  };

  return (
    <>
      {data?.pages?.map(({ data: listingComms }) =>
        listingComms?.map((comm) => (
          <OfferingCommCard
            key={
              comm[
                AUTOMATED_COMM_LISTING_KEYS.has_default_message_content
                  .listingUuid
              ]
            }
            comm={comm}
            commTitle={commTitle}
            onReset={() => handleReset(comm)}
            onEdit={handleEditListingComm(comm)}
          />
        ))
      )}

      {fetching ? (
        <div className="text-center">
          <CircularProgress />
        </div>
      ) : null}

      <div ref={lastElementRef} />
    </>
  );
};
