import { RichTextEditorUtils } from "@my-scoot/component-library-legacy";
import ExlyImage from "common/Components/ExlyImage";
import { ExlyInput } from "common/form";
import { required } from "common/validate";
import { useCustomMutation } from "data/modules/api/hooks/useCustomMutation";
import { AUTOMATED_COMMS_APIS } from "features/AutomatedCommunication/constants/AutomatedComms.api";
import { EDIT_COMM_FE_KEYS } from "features/AutomatedCommunication/modules/EditAutomatedCommunication/constants/EditAutomatedCommunication.constants";
import { ExlyModal } from "features/Common/modules/ExlyModal/ExlyModal";
import { getS3AssetCDNUrl } from "features/Common/modules/File/modules/Upload/utils/FileUpload.utils";
import { validateEmail } from "features/Crm/modules/AddCustomerModal/utils/AddCustomer.validations";
import React from "react";
import { Field, Form, useForm } from "react-final-form";
import { notification_color_keys, useNotifications } from "utils/hooks";
import { composeValidators } from "utils/validations";
import { SEND_TEST_EMAIL_FORM_KEYS } from "./constants/SendTestEmailModal.constants";
import styles from "./SendTestEmailModal.module.css";

const SendTestEmailModal = ({ open, onClose }) => {
  const { notify } = useNotifications();
  const form = useForm();
  const { mutate: sendTestEmail, isLoading } = useCustomMutation({
    url: AUTOMATED_COMMS_APIS.send_test_email,
    onSuccess: handleSuccess,
  });

  function handleSuccess() {
    notify("Test email sent", notification_color_keys.success);
    onClose();
  }

  const handleSendTestEmail = (formData) => {
    const { [SEND_TEST_EMAIL_FORM_KEYS.mailto]: mailto } = formData;
    const emailBodyState = form.getFieldState(EDIT_COMM_FE_KEYS.fe_email_body);
    const emailSubjectState = form.getFieldState(
      EDIT_COMM_FE_KEYS.fe_email_subject
    );
    const emailPayload = {
      subject: emailSubjectState.value,
      body: RichTextEditorUtils.getHtmlFromEditorState(emailBodyState.value),
    };

    sendTestEmail({
      mailto,
      email: emailPayload,
    });
  };

  const commonModalProps = {
    disablePortal: true,
  };

  return (
    <Form onSubmit={handleSendTestEmail} keepDirtyOnReinitialize>
      {({ handleSubmit }) => (
        <form className="position-absolute" onSubmit={handleSubmit}>
          <ExlyModal
            title="Send Test Email"
            open={open}
            onClose={onClose}
            showSecondaryBtn={false}
            primaryBtnText={
              <div className="d-flex align-items-center">
                <ExlyImage
                  className="mr-1"
                  src={getS3AssetCDNUrl("assets/vectors/common/send_arrow.svg")}
                  alt="send"
                />
                Send
              </div>
            }
            onPrimaryBtnClick={handleSubmit}
            className={styles.root}
            primaryBtnProps={{
              fullWidth: true,
              loading: isLoading,
              disabled: isLoading,
            }}
            modal_props={{
              modalPaperClassName: styles.paper,
              ...commonModalProps,
            }}
            mobile_modal_props={commonModalProps}
          >
            <Field
              name={SEND_TEST_EMAIL_FORM_KEYS.mailto}
              component={ExlyInput}
              label="Send the test email to"
              placeholder="Enter Email Address"
              validate={composeValidators(required, validateEmail)}
              fullWidth
            />
          </ExlyModal>
        </form>
      )}
    </Form>
  );
};

export default SendTestEmailModal;
