import { ExlyDropdown, ExlyInput } from "common/form";
import { WhitelabelLockComponent } from "features/Account/modules/Whitelabel/modules/WhitelabelLock/modules/WhitelabelLockComponent/WhitelabelLockComponent";
import { useWhitelabelLock } from "features/Account/modules/Whitelabel/modules/WhitelabelLock/useWhitelabelLock";
import { useWhitelabel } from "features/Account/modules/Whitelabel/utils/useWhitelabel";
import React from "react";
import { Field } from "react-final-form";
import { exly_creator_name } from "ui/pages/whatsappBroadcast/create/CustomTemplates/moduleConstants";
import {
  MASTER_CONTROLS_FE_FORM_KEYS,
  MASTER_CONTROLS_FORM_KEYS,
} from "../MasterControls/constants/MasterControls.constants";
import styles from "./SenderManagement.module.css";
import { EXLY_PHONE_NUMBER } from "features/Common/constants/Exly.constants";

export const SenderManagement = ({ senderSettings }) => {
  const { isAccessible, handleWhitelabelPurchase, whiteLabelDetails } =
    useWhitelabel();
  const { showWhitelabelUnlockModal, toggleWhitelabelUnlockModal } =
    useWhitelabelLock();

  const whatsappNumber =
    senderSettings?.[MASTER_CONTROLS_FORM_KEYS.whatsapp]?.[
      MASTER_CONTROLS_FORM_KEYS.number
    ];
  const whatsappSenderName =
    senderSettings?.[MASTER_CONTROLS_FORM_KEYS.whatsapp]?.[
      MASTER_CONTROLS_FORM_KEYS.name
    ];
  const isExlyNumberSelected = whatsappSenderName === exly_creator_name;

  const LockAction = (
    <WhitelabelLockComponent
      hideLabel
      modalDescription={{
        heading: "Edit Email & Whatsapp Sender Details via White-labelling",
        subHeading: "With white-labelling you can expect the following changes",
        pointers: [
          "Manage sender name and email for all email related communications",
          "Activate WhatsApp personal number for your WhatsApp messages",
          "Manage footer section on your landing page and thank you page",
          "Remove Exly branding from all pages including sales page and checkout pages.",
        ],
      }}
      isAccessible={isAccessible}
      handleWhitelabelPurchase={handleWhitelabelPurchase}
      whiteLabelDetails={whiteLabelDetails}
      showWhitelabelUnlockModal={showWhitelabelUnlockModal}
      toggleWhitelabelUnlockModal={toggleWhitelabelUnlockModal}
    />
  );

  return (
    <>
      <div className={styles.root}>
        <div className={styles.section}>
          <div className={styles.sectionLabel}>
            Email Details
            {LockAction}
          </div>

          <Field
            name={MASTER_CONTROLS_FE_FORM_KEYS.sender_email_name}
            component={ExlyInput}
            label="Sender Name"
            placeholder="Enter sender name"
            disabled={!isAccessible}
            fullWidth
          />

          <Field
            name={MASTER_CONTROLS_FE_FORM_KEYS.sender_email}
            component={ExlyInput}
            label="Sender Email"
            placeholder="Enter sender email"
            disabled={!isAccessible}
            fullWidth
          />
        </div>

        <div className={styles.section}>
          <div className={styles.sectionLabel}>
            WhatsApp Details
            {LockAction}
          </div>

          <Field
            name={MASTER_CONTROLS_FE_FORM_KEYS.sender_use_whitelabel_wa_number}
            component={ExlyDropdown}
            label="Sender Number"
            options={[
              {
                label: `Exly (${EXLY_PHONE_NUMBER})`,
                value: false,
              },
              {
                label: isExlyNumberSelected
                  ? "Your personal number"
                  : `${whatsappSenderName} (${whatsappNumber})`,
                value: true,
              },
            ]}
            size="small"
            disabled={!isAccessible}
            fullWidth
          />

          <Field
            name={MASTER_CONTROLS_FE_FORM_KEYS.sender_whatsapp_name}
            component={ExlyInput}
            label="Sender Name"
            disabled
            fullWidth
          />
        </div>
      </div>
    </>
  );
};
