import React from "react";
import { Route } from "react-router-dom";
import EditAutomatedCommunication from "../EditAutomatedCommunication";

export const EDIT_AUTOMATED_COMM_ROUTE_KEYS = {
  EDIT_AUTOMATED_COMM: "EDIT_AUTOMATED_COMM",
};

export const EDIT_AUTOMATED_COMM_ROUTE_IDS = {
  [EDIT_AUTOMATED_COMM_ROUTE_KEYS.EDIT_AUTOMATED_COMM]: "/edit-comm",
};

export const EDIT_AUTOMATED_COMM_CUSTOM_ROUTES = [
  <Route
    key={
      EDIT_AUTOMATED_COMM_ROUTE_IDS[
        EDIT_AUTOMATED_COMM_ROUTE_KEYS.EDIT_AUTOMATED_COMM
      ]
    }
    path={
      EDIT_AUTOMATED_COMM_ROUTE_IDS[
        EDIT_AUTOMATED_COMM_ROUTE_KEYS.EDIT_AUTOMATED_COMM
      ]
    }
    component={EditAutomatedCommunication}
  />,
];
