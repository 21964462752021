export {
  BRANDED_COMMUNITY_POST_CREATION_KEYS,
  POST_SHARING_TYPES,
} from "@my-scoot/exly-react-component-lib/build/esm/features/BrandedCommunity/constants/BrandedCommunity.constants";

export const BRANDED_COMMUNITY_APIS = {
  reorder_bonus_offerings: "community/reorder/elements",
  get_communities: "community/paginated/list",
  get_community_channels: "community/feed/channel/paginated/list",
};

export const BRANDED_COMMUNITY_REORDER_TYPE = {
  COMMUNITY_GROUP: 1,
  COMMUNITY_OFFERINGS: 2,
};

export const BRANDED_COMMUNITY_QUERY_PARAMS = {
  selected_section: "section",
  selected_channel_uuid: "channel",
  selected_custom_link_uuid: "cl",
  selected_post_uuid: "post",
  selected_comment: "comment",
  selected_comment_reply: "reply",
  selected_message_created_at: "msg_created_at",
};

export const COMMUNITY_ONBOARDING_STEPS = {
  sidenav: 0,
  default_sections: 1,
  create_group_cta: 2,
  groups: 3,
  members_panel: 4,
  create_post_panel: 5,
  create_post: 6,
  poll_post: 7,
  offering_post: 8,
};

export const COMMUNITY_ONBOARDING_STEPS_ORDER = [
  COMMUNITY_ONBOARDING_STEPS.sidenav,
  COMMUNITY_ONBOARDING_STEPS.default_sections,
  COMMUNITY_ONBOARDING_STEPS.create_group_cta,
  COMMUNITY_ONBOARDING_STEPS.groups,
  COMMUNITY_ONBOARDING_STEPS.members_panel,
  COMMUNITY_ONBOARDING_STEPS.create_post_panel,
  COMMUNITY_ONBOARDING_STEPS.create_post,
  COMMUNITY_ONBOARDING_STEPS.poll_post,
  COMMUNITY_ONBOARDING_STEPS.offering_post,
];

export const COMMUNITY_ONBOARDING_STEPS_TOOLTIPS = {
  [COMMUNITY_ONBOARDING_STEPS.sidenav]:
    "Navigation made easier. Now you can easily relocate to and manage anywhere within the community through this nav panel.",
  [COMMUNITY_ONBOARDING_STEPS.default_sections]:
    "With new additions like DMs and Mentions, you can engage in 1-1 chats with your community members as well as become part of conversations through mentions.",
  [COMMUNITY_ONBOARDING_STEPS.create_group_cta]:
    "You can create various collections and add channels in them. Channels can help you segregate conversations around different topics and discussions.",
  [COMMUNITY_ONBOARDING_STEPS.groups]:
    "We have already prepared a collection with some channels, for you to start with. You can edit and/or add anything to it to suit your liking, or remove it if you need to.",
  [COMMUNITY_ONBOARDING_STEPS.members_panel]:
    "You can see all the community members who are currently online. To view a member’s profile, simply click on their name.",
  [COMMUNITY_ONBOARDING_STEPS.create_post_panel]:
    "Need to post about something? Tap here to create exciting posts for your audience!",
  [COMMUNITY_ONBOARDING_STEPS.create_post]:
    "You can add images, videos or docs to your posts.",
  [COMMUNITY_ONBOARDING_STEPS.poll_post]:
    "Spark meaningful engagement and lively discussions in your community with interactive and exciting polls.",
  [COMMUNITY_ONBOARDING_STEPS.offering_post]:
    "You can even promote and upsell a live offering in your community. This way you can drive sales via your community.",
};
