import { getRAUrlParams } from "features/Common/utils/url.utils";
import { create } from "zustand";
import { AUTOMATED_COMMS_TARGETS } from "../constants/AutomatedComms.targets";
import { AUTOMATED_COMMS_URL_PARAMS } from "../constants/AutomatedComms.urlParams";
import {
  handleChangeOfferingsMedium,
  handleInitCommTitlesState,
  handleInitOfferingsEnabledCommsState,
  handleSetActiveTab,
  handleSetCommTitleState,
  handleSetOfferingEnabledComms,
  handleSetParentCommEnabledComms,
  handleUpdateCommEditedStatus,
} from "./useAutomatedCommsStore.utils";

export const useAutomatedCommsStore = create((set) => {
  const urlParams = getRAUrlParams();
  const initialActiveTab =
    urlParams.get(AUTOMATED_COMMS_URL_PARAMS.tab) ||
    AUTOMATED_COMMS_TARGETS.SENT_TO_USERS;

  return {
    activeTab: initialActiveTab,
    commsTitlesState: {},
    setActiveTab: handleSetActiveTab(set),
    initOfferingsEnabledCommsState: handleInitOfferingsEnabledCommsState(set), // triggered from PARENT comm to initialize it's offerings enabled comms mediums
    initCommTitlesState: handleInitCommTitlesState(set), // triggered by tab tables section to initialize all tables state
    setCommTitleState: handleSetCommTitleState(set),
    setOfferingEnabledComms: handleSetOfferingEnabledComms(set), // triggered from OFFERING comm to update it's enabled comms mediums
    setParentCommEnabledComms: handleSetParentCommEnabledComms(set), // triggered by parent comm to set's it's enabled comms
    setOfferingsEnabledMedium: handleChangeOfferingsMedium(set), // triggered from PARENT comm to update it's offerings enabled comms mediums
    setCommEditedState: handleUpdateCommEditedStatus(set), // updates comm edited state for parent/offering comm
  };
});
