export const AUTOMATED_COMMS_COLUMNS = {
  COMM_TITLE: 1,
  EMAIL: 2,
  WHATSAPP: 3,
  SMS: 4,
  ACTIONS: 5,
};

export const AUTOMATED_COMMS_HEADERS_MAP = {
  [AUTOMATED_COMMS_COLUMNS.COMM_TITLE]: {
    title: "Communication title",
    align: "left",
  },
  [AUTOMATED_COMMS_COLUMNS.EMAIL]: { title: "Email", align: "center" },
  [AUTOMATED_COMMS_COLUMNS.WHATSAPP]: { title: "WhatsApp", align: "center" },
  [AUTOMATED_COMMS_COLUMNS.SMS]: { title: "SMS", align: "center" },
  [AUTOMATED_COMMS_COLUMNS.ACTIONS]: { title: "Actions", align: "right" },
};
