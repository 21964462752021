import { isRequestSuccessful } from "@my-scoot/exly-react-component-lib";
import { dataProvider } from "data";
import { apiMethods } from "data/api.constants";
import { TEMPLATE_APPROVAL_STATUS } from "features/CreateWhatsappPresetNotificationTemplate/constants/CreateWhatsappPresetNotificationTemplate.constants";
import { GET_CUSTOM_WHATSAPP_TEMPLATES } from "features/OmniChannel/modules/Whatsapp/modules/WhatsappTemplates/constants/WhatsappTemplates.api";
import { CUSTOM_TEMPLATE_TYPES } from "ui/pages/whatsappBroadcast/modules/CreateCustomTemplateModal/constants/CreateCustomTemplateModal.constants";
import { AUTOMATED_COMMS_APIS } from "../constants/AutomatedComms.api";

export const fetchAutomatedCommListings =
  ({ commTitle }) =>
  async ({ pageParam = 0 }) => {
    const page = pageParam + 1;
    const { data, status } = await dataProvider.custom_request(
      AUTOMATED_COMMS_APIS.fetch_comms_listings,
      apiMethods.GET,
      { comm_title: commTitle, page, page_size: 10 }
    );

    if (!isRequestSuccessful(status))
      throw new Error(`Error fetching automated comms: ${data.message}`);

    return {
      data: data?.listings,
      page,
      totalPages: data?.total_pages,
      total: data?.total,
    };
  };

export const fetchWhatsappPresetNotificationsTemplates =
  (params) =>
  async ({ pageParam = 0 }) => {
    const page = pageParam + 1;
    const { data, status } = await dataProvider.custom_request(
      AUTOMATED_COMMS_APIS.fetch_whatsapp_preset_notifications_templates,
      apiMethods.GET,
      { page, page_size: 10, ...params }
    );

    if (!isRequestSuccessful(status))
      throw new Error(`Error fetching whatsapp templates: ${data.message}`);

    return {
      data: data?.templates,
      page,
      totalPages: data?.total_pages,
      total: data?.total,
    };
  };

export const fetchWhatsappCustomPresetNotificationsTemplates =
  (commTitle) =>
  (params) =>
  async ({ pageParam = 0 }) => {
    const page = pageParam + 1;
    const { data, status } = await dataProvider.custom_request(
      GET_CUSTOM_WHATSAPP_TEMPLATES,
      apiMethods.GET,
      {
        notification_type: CUSTOM_TEMPLATE_TYPES.PRESET_NOTIFICATION,
        approval_status: TEMPLATE_APPROVAL_STATUS.APPROVED,
        parent_comm_title: commTitle,
        page,
        page_size: 10,
        ...params,
      }
    );

    if (!isRequestSuccessful(status))
      throw new Error(`Error fetching whatsapp templates: ${data.message}`);

    return {
      data: data?.custom_templates,
      page,
      totalPages: data?.total_pages,
      total: data?.total,
    };
  };
