import { useForm, useFormState } from "react-final-form";
import { MASTER_CONTROLS_FORM_KEYS } from "../../../constants/MasterControls.constants";

export const useManageMasterSettings = ({ name }) => {
  const {
    values: {
      [name]: categoryWiseComms,
      [MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums]:
        enabledCommsMediums,
    },
  } = useFormState();
  const form = useForm();
  const {
    mutators: { update },
  } = form;

  const handleEnableMasterSettingsMedium = (commType) => {
    if (enabledCommsMediums.indexOf(commType) === -1) {
      form.change(MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums, [
        ...enabledCommsMediums,
        commType,
      ]);
    }
  };

  const generateCommActiveStateChangeHandler =
    (index) => (commType, activate) => {
      const commData = categoryWiseComms[index];
      const {
        [MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums]:
          enabledCommsMediums,
      } = commData;
      let updatedEnabledCommsMediums = [...enabledCommsMediums];

      if (activate) {
        updatedEnabledCommsMediums.push(commType);

        // enable global medium state as well if not enabled already
        handleEnableMasterSettingsMedium(commType);
      } else {
        updatedEnabledCommsMediums = updatedEnabledCommsMediums.filter(
          (type) => type !== commType
        );
      }

      const updatedCommData = {
        ...commData,
        [MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums]:
          updatedEnabledCommsMediums,
      };

      // update form state for this comm
      update(name, index, updatedCommData);
    };

  return { generateCommActiveStateChangeHandler };
};
