import { Button } from "@my-scoot/component-library-legacy";
import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import classnames from "classnames";
import React from "react";
import { renderWhatsappFormatWithVariables } from "ui/pages/whatsappBroadcast/whatsappBroadcast.constants";
import { useToggleState } from "utils/hooks";
import styles from "./WhatsAppTemplate.module.css";

const WhatsAppTemplate = ({ body, title, type, onChoose, onPreview }) => {
  const isDesktop = useIsDesktop();
  const [isActionsShowing, showAction, closeActions] = useToggleState(false);
  const showActionOverlay = isDesktop && isActionsShowing;

  return (
    <div
      className={classnames("tw-flex tw-flex-col", styles.root)}
      onMouseEnter={showAction}
      onMouseLeave={closeActions}
    >
      <div
        className={classnames("tw-flex-1", styles.template)}
        dangerouslySetInnerHTML={{
          __html: renderWhatsappFormatWithVariables({})(body),
        }}
      />

      <div
        className={classnames(
          "d-flex align-items-center justify-content-between tw-bg-white",
          styles.footer
        )}
      >
        <div className={styles.commLabel}>{title}</div>

        <div className={styles.listingType}>{type}</div>
      </div>

      {!isDesktop && (
        <div
          className={classnames(
            "d-flex align-items-center justify-content-between border-top tw-bg-white",
            styles.mobileActions
          )}
        >
          <Button
            size="thin"
            variant="outlined"
            color="secondary"
            onClick={onPreview}
          >
            Preview
          </Button>
          <Button size="thin" onClick={onChoose}>
            Use this template
          </Button>
        </div>
      )}

      {showActionOverlay && (
        <div className={styles.actions}>
          <div className="text-center">
            <Button
              className={classnames("mb-2", styles.selectedTemplateCta)}
              fullWidth
              size="thin"
              onClick={onChoose}
            >
              Use this template
            </Button>

            <Button
              className={styles.previewCta}
              fullWidth
              size="thin"
              onClick={onPreview}
            >
              Preview
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WhatsAppTemplate;
