import CheckboxGroup from "common/Components/CheckboxGroup/CheckboxGroup";
import { useCustomMutation } from "data/modules/api/hooks/useCustomMutation";
import { AUTOMATED_COMMS_APIS } from "features/AutomatedCommunication/constants/AutomatedComms.api";
import {
  AUTOMATED_COMM_LISTING_UPDATE_PAYLOAD_KEYS,
  AUTOMATED_COMM_UPDATE_PAYLOAD_KEYS,
  AUTOMATED_COMMS_TABLE_ROW_KEYS,
  AUTOMATED_SUPPORTED_COMMS_API_VALUES,
  SUPPORTED_COMMS_LABELS,
} from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { useAutomatedCommsStore } from "features/AutomatedCommunication/store/useAutomatedCommsStore";
import { ExlyModal } from "features/Common/modules/ExlyModal/ExlyModal";
import React, { useState } from "react";
import styles from "./ResetCommPopup.module.css";
import { notification_color_keys, useNotifications } from "utils/hooks";

const ResetCommPopup = ({ open, onClose, resetData = {} }) => {
  const { notify } = useNotifications();
  const [selectedMediums, setSelectedMediums] = useState([]);
  const { commTitle, listingUuid } = resetData;
  const { isLoading, mutate: resetComms } = useCustomMutation({
    url: AUTOMATED_COMMS_APIS.update_comm_type_enabled_state(commTitle),
    onSettled: onClose,
    onSuccess: handleSuccess,
  });
  const setCommEditedState = useAutomatedCommsStore(
    (state) => state.setCommEditedState
  );

  function handleSuccess(response) {
    const {
      [AUTOMATED_COMMS_TABLE_ROW_KEYS.has_default_message_content]:
        hasDefaultMessageContent,
    } = response;

    setCommEditedState({
      commTitle,
      offeringUuid: listingUuid,
      hasDefaultSettings: hasDefaultMessageContent,
    });

    notify(
      "Communication reset to original state",
      notification_color_keys.success
    );
  }

  const handleResetParentComm = () => {
    resetComms({
      [AUTOMATED_COMM_UPDATE_PAYLOAD_KEYS.reset_content]: selectedMediums,
    });
  };

  const handleResetListingComm = () => {
    resetComms({
      [AUTOMATED_COMM_UPDATE_PAYLOAD_KEYS.listings]: [
        {
          [AUTOMATED_COMM_LISTING_UPDATE_PAYLOAD_KEYS.uuid]: listingUuid,
          [AUTOMATED_COMM_LISTING_UPDATE_PAYLOAD_KEYS.reset_content]:
            selectedMediums,
        },
      ],
    });
  };

  const handleReset = () => {
    (listingUuid ? handleResetListingComm : handleResetParentComm)();
  };

  return (
    <ExlyModal
      title="Reset Message"
      primaryBtnText="Yes, Reset"
      open={open}
      onClose={onClose}
      className={styles.root}
      onPrimaryBtnClick={handleReset}
      modal_props={{
        footerClassName: styles.footer,
      }}
      primaryBtnProps={{
        loading: isLoading,
        disabled: selectedMediums.length === 0,
      }}
      showSecondaryBtn
    >
      Are you sure you want to reset this message to its original state?
      <CheckboxGroup
        label="Select which mediums to reset the message for"
        labelClassName={styles.checkboxLabel}
        options={[
          {
            id: AUTOMATED_SUPPORTED_COMMS_API_VALUES.EMAIL,
            label:
              SUPPORTED_COMMS_LABELS[
                AUTOMATED_SUPPORTED_COMMS_API_VALUES.EMAIL
              ],
          },
          {
            id: AUTOMATED_SUPPORTED_COMMS_API_VALUES.WHATSAPP,
            label:
              SUPPORTED_COMMS_LABELS[
                AUTOMATED_SUPPORTED_COMMS_API_VALUES.WHATSAPP
              ],
          },
        ]}
        required={false}
        value={selectedMediums}
        onChange={setSelectedMediums}
      />
    </ExlyModal>
  );
};

export default ResetCommPopup;
