import { SUPPORTED_COMMS_OPTIONS } from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { isCommsEqual } from "features/AutomatedCommunication/modules/CommsSettingsModal/utils/CommsSettingsModal.utils";
import { isWhatsAppCommType } from "features/AutomatedCommunication/utils/AutomatedCommunication.utils";
import { useFormFieldValue } from "features/Common/modules/Form/utils/useFormFieldValue";
import React from "react";
import { Field, useForm } from "react-final-form";
import {
  MASTER_CONTROLS_FE_FORM_KEYS,
  MASTER_CONTROLS_FORM_KEYS,
} from "../../constants/MasterControls.constants";
import CommSwitch from "../CommSwitch/CommSwitch";
import styles from "./MasterSettings.module.css";
import {
  disableCommMediumFromComms,
  enableCommMediumFromComms,
} from "./utils/MasterSettings.utils";

export const MasterSettings = () => {
  const enabledCommsMediums = useFormFieldValue(
    MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums
  );
  const form = useForm();

  const handleEnableComm = (commType) => {
    const userCategoryComms = form.getFieldState(
      MASTER_CONTROLS_FE_FORM_KEYS.users_comm_categories
    ).value;
    const creatorCategoryComms = form.getFieldState(
      MASTER_CONTROLS_FE_FORM_KEYS.creator_comm_categories
    ).value;

    form.change(
      MASTER_CONTROLS_FE_FORM_KEYS.users_comm_categories,
      enableCommMediumFromComms(commType, userCategoryComms)
    );
    form.change(
      MASTER_CONTROLS_FE_FORM_KEYS.creator_comm_categories,
      enableCommMediumFromComms(commType, creatorCategoryComms)
    );
  };

  const handleDisbaleComm = (commType) => {
    const userCategoryComms = form.getFieldState(
      MASTER_CONTROLS_FE_FORM_KEYS.users_comm_categories
    ).value;
    const creatorCategoryComms = form.getFieldState(
      MASTER_CONTROLS_FE_FORM_KEYS.creator_comm_categories
    ).value;

    form.change(
      MASTER_CONTROLS_FE_FORM_KEYS.users_comm_categories,
      disableCommMediumFromComms(commType, userCategoryComms)
    );
    form.change(
      MASTER_CONTROLS_FE_FORM_KEYS.creator_comm_categories,
      disableCommMediumFromComms(commType, creatorCategoryComms)
    );
  };

  // TODO: optimize with react-hook-form
  const handleChange = (commType, activate) => {
    let updatedEnabledCommsMediums = [...enabledCommsMediums];

    if (activate) {
      updatedEnabledCommsMediums.push(commType);
      handleEnableComm(commType);
    } else {
      updatedEnabledCommsMediums = updatedEnabledCommsMediums.filter(
        (type) => type !== commType
      );
      handleDisbaleComm(commType);
    }

    form.change(
      MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums,
      updatedEnabledCommsMediums
    );
  };

  return (
    <>
      <Field
        name={MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums}
        component={() => null}
        isEqual={isCommsEqual}
      />

      {SUPPORTED_COMMS_OPTIONS.map((comm) => (
        <CommSwitch
          className={isWhatsAppCommType(comm.value) && styles.whatsAppComm}
          key={comm.value}
          enabled={enabledCommsMediums.includes(comm.value)}
          onChange={handleChange}
          {...comm}
        />
      ))}
    </>
  );
};
