import { useCustomQuery } from "data/modules/api/hooks/useCustomQuery";
import { AUTOMATED_COMMS_APIS } from "../constants/AutomatedComms.api";

export const useAutomatedComms = ({ commsTarget, active }) => {
  return useCustomQuery({
    url: AUTOMATED_COMMS_APIS.fetch_automated_comms(commsTarget),
    queryKey: [AUTOMATED_COMMS_APIS.fetch_automated_comms(commsTarget)],
    cacheTime: 0,
    enabled: active,
  });
};
