import classnames from "classnames";
import { EDIT_COMM_FORM_KEYS } from "features/AutomatedCommunication/modules/EditAutomatedCommunication/constants/EditAutomatedCommunication.constants";
import React from "react";
import { useFormState } from "react-final-form";
import { renderWhatsappFormatWithVariables } from "ui/pages/whatsappBroadcast/whatsappBroadcast.constants";
import PreviewComm from "../PreviewComm/PreviewComm";
import SMSMobileFrame from "./modules/SMSMobileFrame/SMSMobileFrame";
import styles from "./PreviewSMSComm.module.css";

const PreviewSMSComm = (props) => {
  const {
    values: {
      [EDIT_COMM_FORM_KEYS.sms]: { [EDIT_COMM_FORM_KEYS.body]: smsBody },
    },
  } = useFormState();

  return (
    <PreviewComm
      title="SMS Preview"
      subTitle="This is how your message will look like"
      {...props}
    >
      <div
        className={classnames(
          "d-flex justify-content-center align-items-center",
          styles.root
        )}
      >
        <SMSMobileFrame>
          <div
            className={styles.body}
            dangerouslySetInnerHTML={{
              __html: renderWhatsappFormatWithVariables({})(smsBody),
            }}
          />
        </SMSMobileFrame>
      </div>
    </PreviewComm>
  );
};

export default PreviewSMSComm;
