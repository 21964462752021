import PersonalizeCta from "features/CustomerComms/modules/PersonalizeCta/PersonalizeCta";
import React from "react";
import styles from "./CommFooter.module.css";

export const CommFooter = ({ onClickPersonalize, isPersonalizeActive }) => {
  return (
    <div className={styles.root}>
      <PersonalizeCta
        active={isPersonalizeActive}
        onClick={onClickPersonalize}
      />
    </div>
  );
};
