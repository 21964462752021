import { useIsDesktop } from "@my-scoot/exly-react-component-lib";
import { ExlyModal } from "features/Common/modules/ExlyModal/ExlyModal";
import React from "react";
import CommDesktopPreview from "./modules/CommDesktopPreview/CommDesktopPreview";
import styles from "./PreviewComm.module.css";

const PreviewComm = ({ title, subTitle, open, onClose, children }) => {
  const isDesktop = useIsDesktop();
  const PreviewComponent = isDesktop ? CommDesktopPreview : ExlyModal;

  return (
    <PreviewComponent
      title={
        <div>
          <div className={styles.title}>{title}</div>
          <div className={styles.info}>{subTitle}</div>
        </div>
      }
      open={open}
      onClose={onClose}
      customFooter={<></>}
    >
      {children}
    </PreviewComponent>
  );
};

export default PreviewComm;
