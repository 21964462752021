import { Button } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import { AUTOMATED_COMMS_URL_PARAMS } from "features/AutomatedCommunication/constants/AutomatedComms.urlParams";
import { isSMSCommType } from "features/AutomatedCommunication/utils/AutomatedCommunication.utils";
import { getRAUrlParams } from "features/Common/utils/url.utils";
import React from "react";
import styles from "./EditCommsMobileHeader.module.css";

const EditCommsMobileHeader = ({ commType, isSubmitting, onSave }) => {
  const urlParams = getRAUrlParams();
  const commInfo = urlParams.get(AUTOMATED_COMMS_URL_PARAMS.comm_info);

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="mr-1">
        <div className="mb-1">Edit Message</div>
        <div className={classnames(styles.infoWrapper)}>
          <div className={styles.commLabel}>{commInfo}</div>
        </div>
      </div>

      {isSMSCommType(commType) ? undefined : (
        <Button
          size="small"
          onClick={onSave}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Save
        </Button>
      )}
    </div>
  );
};

export default EditCommsMobileHeader;
