import { RichTextEditorUtils } from "@my-scoot/component-library-legacy";
import React from "react";
import PreviewComm from "../PreviewComm/PreviewComm";
import styles from "./PreviewEmailComm.module.css";

const PreviewEmailComm = (props) => {
  const { state } = props;

  return (
    <PreviewComm
      title="Email Preview"
      subTitle="This is how your email will look like"
      {...props}
    >
      <div
        className={styles.body}
        dangerouslySetInnerHTML={{
          __html: state
            ? RichTextEditorUtils.getHtmlFromEditorState(state)
            : "",
        }}
      />
    </PreviewComm>
  );
};

export default PreviewEmailComm;
