import { IconButton } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Checkbox } from "@my-scoot/component-library-legacy";
import classnames from "classnames";
import { SOMETHING_WENT_WRONG } from "constants/displayStrings";
import { useCustomMutation } from "data/modules/api/hooks/useCustomMutation";
import { AUTOMATED_COMMS_APIS } from "features/AutomatedCommunication/constants/AutomatedComms.api";
import {
  AUTOMATED_COMM_UPDATE_PAYLOAD_KEYS,
  AUTOMATED_COMMS_TABLE_FE_KEYS,
  AUTOMATED_COMMS_TABLE_ROW_KEYS,
} from "features/AutomatedCommunication/constants/AutomatedComms.constants";
import { useAutomatedCommsStore } from "features/AutomatedCommunication/store/useAutomatedCommsStore";
import {
  useCommStateValue,
  useParentCommStateValue,
} from "features/AutomatedCommunication/store/useAutomatedCommsStore.utils";
import {
  canEditComm,
  isTargetCommCreator,
} from "features/AutomatedCommunication/utils/AutomatedCommunication.utils";
import React from "react";
import { logError } from "utils/error";
import {
  notification_color_keys,
  useNotifications,
  useToggleState,
} from "utils/hooks";
import ActionsPopup from "../ActionsPopup/ActionsPopup";
import { getCommColumns } from "../CommRow/utils/CommRow.utils";
import { MediumCheckboxWrapper } from "../MediumCheckboxWrapper/MediumCheckboxWrapper";
import styles from "./MobileCommRow.module.css";

const MobileCommRow = ({ categoryKey, comm, onReset, onEdit }) => {
  const { notify } = useNotifications();
  const {
    [AUTOMATED_COMMS_TABLE_ROW_KEYS.title]: commTitle,
    [AUTOMATED_COMMS_TABLE_ROW_KEYS.cdb_type]: cdbType,
    [AUTOMATED_COMMS_TABLE_ROW_KEYS.trigger]: commInfo,
    [AUTOMATED_COMMS_TABLE_ROW_KEYS.total_listing_count]: totalListingCount,
    [AUTOMATED_COMMS_TABLE_ROW_KEYS.allowed_communication_mediums]:
      parentAllowedCommsMediums,
  } = comm;
  const { mutate: toggleCommTypeState } = useCustomMutation({
    url: AUTOMATED_COMMS_APIS.update_comm_type_enabled_state(commTitle),
    onError: () => {},
  });
  const [isActionPopupOpen, openActionPopup, closeActionPopup] =
    useToggleState(false);
  const enabledComms =
    useParentCommStateValue({
      commTitle,
      key: AUTOMATED_COMMS_TABLE_FE_KEYS.fe_enabled_mediums,
    }) ?? [];
  const setParentCommEnabledComms = useAutomatedCommsStore(
    (state) => state.setParentCommEnabledComms
  );
  const setOfferingsEnabledMedium = useAutomatedCommsStore(
    (state) => state.setOfferingsEnabledMedium
  );
  const activeTab = useAutomatedCommsStore((state) => state.activeTab);
  const hasDefaultSettings = useCommStateValue({
    commTitle,
    key: AUTOMATED_COMMS_TABLE_ROW_KEYS.has_default_message_content,
  });
  const mediumContainingAnyDisbaledOffering = useCommStateValue({
    commTitle,
    key: AUTOMATED_COMMS_TABLE_FE_KEYS.fe_is_any_listing_comms_disabled,
  });

  const handleToggleCommTypeActiveState = (inputCommType) => (activate) => {
    const prevEnabledComms = [...enabledComms];

    const handleRevert = () => {
      setParentCommEnabledComms({
        commTitle,
        enabledComms: prevEnabledComms,
        targetMedium: inputCommType,
        activate,
      });
      setOfferingsEnabledMedium({
        commTitle,
        medium: inputCommType,
        checked: !activate,
      });
    };

    const handleError = (error) => {
      handleRevert();
      logError({
        error,
        when: "updating automated comms parent comm",
        severity: "blocker",
        occuredAt:
          "src/features/AutomatedCommunication/modules/CommsTable/modules/MobileCommItem/MobileCommRow.jsxƒ",
      });
      notify(
        error?.response?.data?.message || SOMETHING_WENT_WRONG,
        notification_color_keys.error
      );
    };

    let updatedEnabledComms = [...enabledComms];

    if (activate) {
      // enable comm type
      updatedEnabledComms.push(inputCommType);
    } else {
      // disable comm type
      updatedEnabledComms = updatedEnabledComms.filter(
        (commType) => commType !== inputCommType
      );
    }

    setOfferingsEnabledMedium({
      commTitle,
      medium: inputCommType,
      checked: activate,
    });
    setParentCommEnabledComms({
      commTitle,
      enabledComms: updatedEnabledComms,
      targetMedium: inputCommType,
      activate,
    });
    toggleCommTypeState(
      {
        [AUTOMATED_COMM_UPDATE_PAYLOAD_KEYS.enabled_communication_mediums]: [
          { [inputCommType]: activate },
        ],
      },
      { onError: handleError }
    );
  };

  const commsColumns = getCommColumns({
    allowedCommsMediums: parentAllowedCommsMediums,
    enabledCommsMediums: enabledComms,
    mediumContainingAnyDisbaledOffering,
    totalListingCount,
    onChangeGetter: handleToggleCommTypeActiveState,
  });

  const canEdit =
    canEditComm(parentAllowedCommsMediums) && !isTargetCommCreator(activeTab);
  const showMoreActions = canEdit || !!totalListingCount || !hasDefaultSettings;

  return (
    <div className={styles.root}>
      <div
        className={classnames(
          "d-flex align-items-center justify-content-between",
          styles.header
        )}
      >
        <div>
          <div className={styles.title}>{cdbType}</div>
          <div className={styles.info}>{commInfo}</div>
        </div>

        {showMoreActions && (
          <IconButton className="p-0" onClick={openActionPopup}>
            <MoreHorizIcon />
          </IconButton>
        )}
      </div>

      <div
        className={classnames(
          "d-flex align-items-center justify-content-between",
          styles.body
        )}
      >
        {commsColumns.map(
          ({ type, label, checked, disabled, indeterminate, onChange }) => (
            <div className="d-flex flex-column align-items-center" key={type}>
              <MediumCheckboxWrapper disabled={disabled} medium={type}>
                <Checkbox
                  id={label}
                  color="#00B77A"
                  checked={checked}
                  disabled={disabled}
                  indeterminate={indeterminate}
                  onChange={(e, checked) =>
                    onChange(indeterminate ? true : checked)
                  }
                />
              </MediumCheckboxWrapper>

              <label htmlFor={label} className={styles.commLabel}>
                {label}
              </label>
            </div>
          )
        )}
      </div>

      <ActionsPopup
        comm={comm}
        categoryKey={categoryKey}
        open={isActionPopupOpen}
        onClose={closeActionPopup}
        onReset={onReset}
        onEdit={onEdit}
        canEdit={canEdit}
      />
    </div>
  );
};

export default MobileCommRow;
