import { finalFormFieldArrayNameGenerator } from "utils/string";

export const EDIT_COMM_FORM_KEYS = {
  comm_type: "comm_type",
  sms: "sms",
  email: "email",
  subject: "subject",
  variables: "variables",
  whatsapp: "whatsapp",
  body: "body",
  template_uuid: "template_uuid",
  use_whitelabel_wa_number: "use_whitelabel_wa_number",
  allowed_communication_mediums: "allowed_communication_mediums",
};

export const EDIT_COMM_FE_KEYS = {
  fe_email_body: "fe_email_body",
  fe_email_subject: finalFormFieldArrayNameGenerator([
    EDIT_COMM_FORM_KEYS.email,
    EDIT_COMM_FORM_KEYS.subject,
  ]),
  fe_whatsapp_body: finalFormFieldArrayNameGenerator([
    EDIT_COMM_FORM_KEYS.whatsapp,
    EDIT_COMM_FORM_KEYS.body,
  ]),
  fe_whatsapp_template_uuid: finalFormFieldArrayNameGenerator([
    EDIT_COMM_FORM_KEYS.whatsapp,
    EDIT_COMM_FORM_KEYS.template_uuid,
  ]),
  fe_use_whitelabel_wa_number: finalFormFieldArrayNameGenerator([
    EDIT_COMM_FORM_KEYS.whatsapp,
    EDIT_COMM_FORM_KEYS.use_whitelabel_wa_number,
  ]),
};

export const EDIT_COMM_URL_PARAMS = {
  ct: "ct", // comm type
  ces: "ces", // comm edit status
};

export const COMM_EDIT_STATUS = {
  SUCCESS: 1,
};
