import { CircularProgress } from "@material-ui/core";
import { Tabs } from "@my-scoot/component-library-legacy";
import { AUTOMATED_COMMS_TARGETS } from "features/AutomatedCommunication/constants/AutomatedComms.targets";
import { ExlyModal } from "features/Common/modules/ExlyModal/ExlyModal";
import React, { useEffect, useState } from "react";
import { useForm } from "react-final-form";
import { exly_creator_name } from "ui/pages/whatsappBroadcast/create/CustomTemplates/moduleConstants";
import {
  COMMS_SETTINGS_MASTER_CONTROLS_TAB,
  COMMS_SETTINGS_SENDER_MANAGEMENT_TAB,
  COMMS_SETTINGS_TABS,
} from "../../constants/CommsSettingsModal.constants";
import { useCommSettingsModal } from "../../utils/useCommSettingsModal";
import {
  MASTER_CONTROLS_FE_FORM_KEYS,
  MASTER_CONTROLS_FORM_KEYS,
} from "../MasterControls/constants/MasterControls.constants";
import { MasterControls } from "../MasterControls/MasterControls";
import { SenderManagement } from "../SenderManagement/SenderManagement";
import styles from "./CommsSettingsModalBody.module.css";

export const CommsSettingsModalBody = ({
  open,
  onClose,
  handleSubmit,
  submitting,
}) => {
  const form = useForm();
  const [selectedTab, setSelectedTab] = useState(
    COMMS_SETTINGS_MASTER_CONTROLS_TAB.value
  );

  const handleTabChange = (e, value) => setSelectedTab(value);

  const {
    isLoaded,

    // TAB = COMMS_SETTINGS_MASTER_CONTROLS_TAB
    creatorCommsSettings,
    userCommsSettings,

    // TAB = COMMS_SETTINGS_SENDER_MANAGEMENT_TAB
    senderSettings,
  } = useCommSettingsModal({ enabled: open });

  useEffect(() => {
    // initialize form values
    const handleInitForm = () => {
      if (
        isLoaded &&
        creatorCommsSettings &&
        userCommsSettings &&
        senderSettings
      ) {
        const {
          [MASTER_CONTROLS_FORM_KEYS.whatsapp]: {
            [MASTER_CONTROLS_FORM_KEYS.name]: waName,
          },
        } = senderSettings;
        const isExlyNumberSelected = waName === exly_creator_name;

        form.reset({
          [MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums]:
            userCommsSettings[
              MASTER_CONTROLS_FORM_KEYS.enabled_communication_mediums
            ],
          [MASTER_CONTROLS_FORM_KEYS.sent_to]:
            AUTOMATED_COMMS_TARGETS.SENT_TO_USERS,
          [MASTER_CONTROLS_FE_FORM_KEYS.users_comm_categories]:
            userCommsSettings.categories,
          [MASTER_CONTROLS_FE_FORM_KEYS.creator_comm_categories]:
            creatorCommsSettings.categories,

          [MASTER_CONTROLS_FORM_KEYS.sender_management]: {
            ...senderSettings,
            [MASTER_CONTROLS_FORM_KEYS.whatsapp]: {
              [MASTER_CONTROLS_FORM_KEYS.use_whitelabel_wa_number]:
                !isExlyNumberSelected,
              [MASTER_CONTROLS_FORM_KEYS.name]: waName,
            },
          },
        });
      }
    };

    handleInitForm();
  }, [isLoaded, creatorCommsSettings, userCommsSettings]);

  return (
    <ExlyModal
      title="Settings"
      primaryBtnText="Done"
      open={open}
      onClose={onClose}
      onPrimaryBtnClick={handleSubmit}
      className={styles.root}
      modal_props={{
        modalPaperClassName: styles.modalPaper,
      }}
      primaryBtnProps={{
        loading: submitting,
        disabled: submitting,
      }}
    >
      {isLoaded ? (
        <>
          <div className={styles.tabsWrapper}>
            <div className={styles.tabsRuler} />
            <Tabs
              value={selectedTab}
              tabs={COMMS_SETTINGS_TABS}
              onChange={handleTabChange}
            />
          </div>

          <div className={styles.body}>
            <div
              className={
                selectedTab === COMMS_SETTINGS_MASTER_CONTROLS_TAB.value
                  ? undefined
                  : "d-none"
              }
            >
              <MasterControls />
            </div>

            <div
              className={
                selectedTab === COMMS_SETTINGS_SENDER_MANAGEMENT_TAB.value
                  ? undefined
                  : "d-none"
              }
            >
              <SenderManagement senderSettings={senderSettings} />
            </div>
          </div>
        </>
      ) : (
        <div className="text-center m-4">
          <CircularProgress />
        </div>
      )}
    </ExlyModal>
  );
};
